// 富文本解析

<template>
  <el-skeleton
    :rows="15"
    animated
    v-if="JSON.stringify(information) === '{}'"
  />
  <div class="px-52 py-3" v-else>
    <div class="flex items-center">
      <div class="w-14 h-6 rounded text-white bg-black">资讯</div>
      <div class="ml-4 text-lg font-bold">
        {{ information?.informationTitle }}
      </div>
    </div>
    <div class="flex justify-start mt-4">
      <div
        class="min-w-16 text-center h-5 rounded-2xl px-2 bg-[#DBDBDB] text-[#5e5e5e] mr-2 leading-5"
      >
        ADIDAS
      </div>
    </div>
    <div class="mt-4" v-html="information?.txt"></div>
  </div>
</template>

<script>
import { getInformationDetail } from '@/api/information'

export default {
  methods: {
    async getDetail() {
      const id = this.$route.params.id
      if (!id) {
        this.$message.error('参数错误')
        this.$router.go(-1)
        return
      }
      const result = await getInformationDetail(id)
      this.information = result.data
    }
  },
  data() {
    return {
      information: {}
    }
  },
  mounted() {
    this.getDetail()
  }
}
</script>

<style>
</style>