<template>
  <div>
    <div class="grid grid-cols-3 gap-2" v-if="imgList.length > 0">
      <img
        :src="item.imageUrlVisit"
        class="w-[131px] h-[131px] rounded-2xl"
        v-for="(item, index) in imgList"
        :key="index"
      />
    </div>
    <div class="w-full h-96 flex justify-center items-center" v-else>
      <el-empty description="暂无历史记录,快去生成吧~"></el-empty>
    </div>
    <el-pagination
      background
      layout="total, prev, pager, next"
      :total="imgList.length"
      :hide-on-single-page="true"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getUserHistory } from '@/api/workbench'

export default {
  data() {
    return {
      limit: 18,
      page: 1,
      total: 0,
      imgList: []
    }
  },
  methods: {
    async getHistory() {
      const result = await getUserHistory({
        page: this.page,
        limit: this.limit
      })
      if (result) {
        this.total = result.total
        this.imgList = result.rows
      }
    }
  },
  mounted() {
    this.getHistory()
  }
}
</script>

<style>
</style>