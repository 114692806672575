<template>
  <div class="mt-4">
    <div class="p-3 border-b pl-14 text-left font-extrabold">
      评论：{{ total }}条
    </div>
    <div class="w-full border-b p-2" v-if="commentList.length > 0">
      <div v-for="(item, index) in commentList" :key="index" class="py-4">
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <img :src="item.sendUser?.avatar" class="w-12 h-12" alt="" />
            <span class="ml-3">{{ item.sendUser?.createBy }}</span>
            <span class="ml-3">{{ item.sendUser?.createTime }}</span>
          </div>
          <div>
            <div
              class="ml-10 cursor-pointer flex items-center"
              @click="likeComments(item)"
            >
              <img
                class="w-5 h-5 mr-2"
                :src="
                  item.userIsLike
                    ? require('@/assets/loveRed.svg')
                    : require('@/assets/loveBlack.svg')
                "
              />
              {{ item.likeCount }}
            </div>
          </div>
        </div>
        <div class="py-10 text-left">
          {{ item.comment }}
        </div>
      </div>
    </div>
    <div
      class="min-h-72 flex flex-col justify-center items-center border-b"
      v-else
    >
      <img src="@/assets/comments.svg" alt="" class="mt-4" />
      <div class="mt-4 text-[#999999] text-lg">暂时还没有评论</div>
      <div class="mt-2 text-[#999999] text-lg">快来说说你的看法</div>
    </div>
    <div class="w-full flex p-3 items-center">
      <div class="w-1/3 flex items-center">
        <!-- <div class="cursor-pointer">
          <img src="@/assets/starBlack.svg" alt="" srcset="" />
          {{}}
        </div> -->
        <div class="ml-10 cursor-pointer" @click="like">
          <img
            :src="
              likeInfo.isLike
                ? require('@/assets/loveRed.svg')
                : require('@/assets/loveBlack.svg')
            "
            class="mb-2"
          />
          {{ likeInfo.likeCount }}
        </div>
        <div class="ml-10 cursor-pointer">
          <img src="@/assets/discussBlack.svg" class="mb-2" />
          {{ total }}
        </div>
      </div>
      <div class="flex-1">
        <el-input
          v-model="content"
          placeholder="说点什么吧"
          @keyup.enter.native="publish"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getarticleCommentList,
  likeArticle,
  notLikeArticle,
  commentArticle,
  likeComment,
  notLikeComment
} from '@/api/work'
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    likeInfo: {
      type: Object,
      default: () => ({
        isLike: false,
        likeCount: 0
      })
    }
  },
  data() {
    return {
      total: 0,
      commentList: [],
      content: ''
    }
  },
  methods: {
    /** 获取评论列表 */
    async getCommentList() {
      const result = await getarticleCommentList(this.id)
      this.commentList = result.rows
      this.total = result.total
    },
    /** (取消)点赞 */
    async like() {
      console.log(this.likeInfo)
      const result = !this.likeInfo.isLike
        ? await likeArticle(this.id)
        : await notLikeArticle(this.id)
      this.likeInfo.isLike = !this.likeInfo.isLike
      !this.likeInfo.isLike
        ? this.likeInfo.likeCount--
        : this.likeInfo.likeCount++
      this.$message.success(result.msg)
    },
    async likeComments(item) {
      const result = !item.userIsLike
        ? await likeComment(item.id)
        : await notLikeComment(item.id)
      item.userIsLike = !item.userIsLike
      !item.userIsLike ? item.likeCount-- : item.likeCount++
      this.$message.success(result.msg)
    },
    /** 发布评论 */
    async publish() {
      const result = await commentArticle({
        articleId: this.id,
        comment: this.content
      })
      this.$message.success(result.msg)
      this.content = ''
      this.getCommentList()
    }
  },
  mounted() {
    this.getCommentList()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  background: #f0f0f0;
  border-radius: 20px;
}
</style>