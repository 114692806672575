import information from "@/views/information/index.vue"
import discoverDetail from "@/views/discover/detail.vue"
import informationDetail from "@/views/information/detail.vue"
import informationDetails from "@/views/information/details.vue"

export default [{
    path: "/information",
    name: "information",
    component: information,
    meta: {
      title: '设辑-资讯'
    }
  },
  {
    path: "/information/detail/:id",
    name: "informationDetail",
    component: informationDetail,
    meta: {
      title: '资讯详情'
    }
  },
  {
    path: "/information/details/:id",
    name: "informationDetails",
    component: informationDetails,
    meta: {
        useFooter: false,
        title: '资讯详情'
    }
  }
]