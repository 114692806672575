<template>
  <div>
    <div class="flex justify-center">
      <div class="w-[898px]">
        <div class="w-full h-[692px] flex justify-center items-center relative">
          <img
            :src="info.itemList[0].imageUrl"
            alt=""
            class="w-[750px] h-[650px]"
          />
        </div>
      </div>
      <div class="w-[389px] h-[237px] ml-6">
        <div class="mt-4">
          <div class="w-full text-left">创作信息</div>
          <div class="w-full min-h-16 bg-[#F4F5F7] rounded-xl mt-2 p-6">
            <div>
              <div class="flex justify-between">
                <div class="text-[15px] text-[#878787] font-medium">
                  生图方式
                </div>
                <div class="text-[#444444] text-[15px] font-medium">
                  {{ ['', '文生图', '', '图生图'][info.taskType] }}
                </div>
              </div>
              <div class="mt-4">
                <div
                  class="w-full text-left text-[15px] text-[#878787] font-medium"
                >
                  补充描述
                </div>
                <div class="mt-2 w-11/12 rounded-xl bg-white p-2 text-left">
                  {{ info.reversePrompt || '无' }}
                </div>
              </div>
              <div class="mt-4 flex justify-between">
                <div class="text-[15px] text-[#878787] font-medium">参考图</div>
                <div
                  class="text-[#54C752] text-[15px] font-medium cursor-pointer"
                >
                  查看
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <div class="w-full text-left">基础信息</div>
          <div class="w-full min-h-16 bg-[#F4F5F7] rounded-xl mt-2 p-6">
            <div class="flex justify-between">
              <div class="text-[15px] text-[#878787] font-medium">创作者</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.creatorUser.userName }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">创建时间</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.createTime }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">画面尺寸</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.aspectRatio }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">模型主题</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.modelTheme }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">生成模式</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.processMode }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">所属合集</div>
              <div
                class="text-[#54C752] text-[15px] font-medium cursor-pointer"
              >
                查看
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <div
            class="w-full h-[73px] bg-black rounded-xl flex justify-around items-center p-4 text-[#B2B4BB]"
          >
            <div
              class="flex flex-col items-center justify-center cursor-pointer"
            >
              <img src="@/assets/regenerator.svg" class="w-5 h-5" alt="" />
              <div class="mt-2 text-sm">再次生成</div>
            </div>
            <div
              class="flex flex-col items-center justify-center cursor-pointer"
            >
              <img src="@/assets/panel.svg" class="w-5 h-5" alt="" />
              <div class="mt-2 text-sm">操作面板</div>
            </div>
            <div
              class="flex flex-col items-center justify-center cursor-pointer"
            >
              <img src="@/assets/download.svg" class="w-5 h-5" alt="" />
              <div class="mt-2 text-sm">下载</div>
            </div>
            <div
              class="flex flex-col items-center justify-center cursor-pointer"
            >
              <img src="@/assets/publish.svg" class="w-5 h-5" alt="" />
              <div class="mt-2 text-sm">发布</div>
            </div>
            <div
              class="flex flex-col items-center justify-center cursor-pointer"
            >
              <img src="@/assets/delete.svg" class="w-5 h-5" alt="" />
              <div class="mt-2 text-sm">删除</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getWorksDetail } from '@/api/work'
import { getJobDetail } from '@/api/workbench'
import comments from '@/components/comments'
export default {
  components: {
    comments
  },
  data() {
    return {
      info: {},
      activeImage: 0
    }
  },
  methods: {
    async getInfo() {
      const id = this.$route.params.jobId
      if (!id) {
        this.$message.error('参数错误')
        this.$router.go(-1)
      }
      const result = await getJobDetail(id)
      if (result) {
        this.info = result.data
      }
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>
  
  <style lang="scss" scoped>
.tag {
  background: linear-gradient(
    104deg,
    #e8b45a 2%,
    rgba(235, 217, 100, 0.75) 97%,
    rgba(232, 203, 17, 0.51) 97%
  );
}
</style>