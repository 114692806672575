<template>
  <div>
    <div class="relative w-[1800px] mx-auto" :class="useFooter && ''">
      <sheji-header v-if="useHeader" />
      <div class="px-4 py-6">
        <router-view />
      </div>
    </div>
    <sheji-footer v-if="useFooter" />
  </div>
</template>

<script>
import shejiHeader from '@/components/shejiHeader'
import shejiFooter from '@/components/shejiFooter'
export default {
  components: {
    shejiHeader,
    shejiFooter
  },
  computed: {
    useHeader() {
      if (this.$route?.meta?.useHeader != undefined) {
        return this.$route?.meta?.useHeader
      }
      return true
    },
    useFooter() {
      if (this.$route?.meta?.useFooter != undefined) {
        return this.$route?.meta?.useFooter
      }
      return true
    }
  }
}
</script>

<style>
</style>