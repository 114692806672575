<template>
  <div class="w-full h-full flex flex-col">
    <div
      class="flex justify-center items-center"
      style="height: calc(100% - 112px)"
    >
      <div
        class="w-[437px] h-[437px] bg-white border border-dashed border-[#C1C1C1]"
        v-if="JSON.stringify(result) === '{}' && !isWait"
      ></div>
      <div
        v-else-if="isWait"
        class="w-[437px] h-[437px] relative flex justify-center items-center"
      >
        <img src="@/assets/waitBg.svg" class="absolute top-0 left-0" alt="" />
        <div
          class="w-[150px] h-[150px] p-2 px-4 text-white bg-[rgba(0,0,0,.39)] rounded-lg z-[999]"
        >
          <div class="mt-6">{{ progress }}%</div>
          <div class="my-2">正在生成中...</div>
          <div>
            <el-progress
              :percentage="progress"
              color="#54C752"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
      </div>
      <div class="w-[537px] h-[437px] flex" v-else>
        <div class="flex flex-col">
          <img
            :src="item.imageUrlVisit"
            alt=""
            v-for="(item, index) in result?.itemList"
            :key="index"
            class="w-24 h-24 rounded-xl mt-2 cursor-pointer"
            :class="activeImage === index ? 'border-2 border-[#54C752]' : ''"
            @click="activeImage = index"
          />
        </div>
        <div class="ml-4 mt-2 relative">
          <div
            class="absolute top-4 left-4 w-7 h-7 rounded-full leading-7 text-center bg-[rgba(0,0,0,.5)] text-white"
          >
            {{ activeImage + 1 }}
          </div>
          <img
            :src="result.itemList[activeImage].originUrlVisit"
            alt=""
            class="w-[407px] h-[407px] rounded-xl"
          />
        </div>
      </div>
    </div>
    <div
      class="absolute bottom-0 h-28 w-full flex justify-center"
      :class="JSON.stringify(result) === '{}' ? 'h-28' : 'h-52'"
    >
      <div
        class="w-[550px] h-14 bg-white rounded-3xl flex justify-center items-center"
        v-if="JSON.stringify(result) === '{}'"
      >
        <div
          class="w-[520px] rounded-3xl h-10 bg-[#54C752] text-white relative flex justify-center items-center cursor-pointer"
          @click="isWait ? $emit('revocation') : $emit('generator')"
        >
          <i class="el-icon-loading mr-4" v-if="isWait"></i>
          <div class="text-lg font-bold">
            {{ isWait ? '生成中，点击撤销' : '立即生成' }}
          </div>
          <el-popover
            class="absolute right-2"
            placement="top"
            trigger="hover"
            v-model="popoverVisible"
            popper-class="rounded-[14px]"
          >
            <div
              class="w-8 h-8 rounded-full flex justify-center items-center cursor-pointer bg-[rgba(255,255,255,.4)]"
              slot="reference"
            >
              <img src="@/assets/tipmenu.svg" alt="" />
            </div>
            <!-- 权益及优惠券信息 -->
            <div class="flex flex-col items-center justify-center w-[215px]">
              <div class="text-lg font-bold text-black">AI生成明细</div>
              <div class="p-2 w-full">
                <div
                  v-if="couponInfo?.coupon && couponInfo?.coupon?.length > 0"
                >
                  <div>电力券消费</div>
                </div>
                <el-divider v-if="couponInfo?.coupon"></el-divider>
                <div v-if="couponInfo?.vip && couponInfo?.vip?.length > 0">
                  <div class="text-left font-medium mb-2 text-black">
                    权益卡消耗
                  </div>
                  <div
                    class="flex justify-between mb-2"
                    v-for="(item, index) in couponInfo.vip"
                    :key="index"
                  >
                    <div class="text-xs font-medium text-[#666666]">
                      {{ item.key }}
                    </div>
                    <div
                      class="text-xs font-medium"
                      :style="{
                        color: item?.color ? item.color : '#000'
                      }"
                    >
                      {{ item.value }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-[171px] h-[36px] rounded-[100px] leading-[36px] text-center bg-[#54C752] text-lg font-bold text-white cursor-pointer"
                @click="popoverVisible = false"
              >
                知道了
              </div>
            </div>
          </el-popover>
        </div>
      </div>
      <div
        class="w-[823px] h-[201px] bg-black rounded-[14px] p-4 text-white"
        v-else
      >
        <div class="w-full p-1 pb-5 border-b flex">
          <div class="flex-1 border-r">
            <div class="flex items-center">
              <div class="text-[15px] font-medium">微调</div>
              <div class="text-sm text-[#737373] ml-2">
                仅支持操作（3天）内的生成结果
              </div>
            </div>
            <div class="flex mt-3 mb-3">
              <div
                class="w-16 h-8 leading-8 bg-[#23252F] rounded-md mr-1 cursor-pointer select-none"
                :class="
                  isMoreThanThree && 'cursor-not-allowed pointer-events-none'
                "
                @click="$emit('update', '1')"
              >
                图一
              </div>
              <div
                class="w-16 h-8 leading-8 bg-[#23252F] rounded-md mr-1 cursor-pointer select-none"
                :class="
                  isMoreThanThree && 'cursor-not-allowed pointer-events-none'
                "
                @click="$emit('update', '2')"
              >
                图二
              </div>
              <div
                class="w-16 h-8 leading-8 bg-[#23252F] rounded-md mr-1 cursor-pointer select-none"
                :class="
                  isMoreThanThree && 'cursor-not-allowed pointer-events-none'
                "
                @click="$emit('update', '3')"
              >
                图三
              </div>
              <div
                class="w-16 h-8 leading-8 bg-[#23252F] rounded-md mr-1 cursor-pointer select-none"
                :class="
                  isMoreThanThree && 'cursor-not-allowed pointer-events-none'
                "
                @click="$emit('update', '4')"
              >
                图四
              </div>
              <div class="border-r h-8 mx-4"></div>
              <el-popover
                placement="top"
                trigger="hover"
                v-model="popoverVisible"
                popper-class="rounded-[14px]"
              >
                <div
                  class="w-8 h-8 flex justify-center items-center rounded-md bg-[#23252F] cursor-pointer"
                  slot="reference"
                >
                  <img src="@/assets/tipmenu.svg" alt="" />
                </div>
                <!-- 权益及优惠券信息 -->
                <div
                  class="flex flex-col items-center justify-center w-[215px]"
                >
                  <div class="text-lg font-bold text-black">AI生成明细</div>
                  <div class="p-2 w-full">
                    <div v-if="couponInfo?.coupon">
                      <div>电力券消费</div>
                    </div>
                    <el-divider v-if="couponInfo?.coupon"></el-divider>
                    <div v-if="couponInfo?.vip">
                      <div class="text-left font-medium mb-2 text-black">
                        权益卡消耗
                      </div>
                      <div
                        class="flex justify-between mb-2"
                        v-for="(item, index) in couponInfo.vip"
                        :key="index"
                      >
                        <div class="text-xs font-medium text-[#666666]">
                          {{ item.key }}
                        </div>
                        <div
                          class="text-xs font-medium"
                          :style="{
                            color: item?.color ? item.color : '#000'
                          }"
                        >
                          {{ item.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="w-[171px] h-[36px] rounded-[100px] leading-[36px] text-center bg-[#54C752] text-lg font-bold text-white cursor-pointer"
                    @click="popoverVisible = false"
                  >
                    知道了
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
          <div class="flex-1 pl-6">
            <div class="flex items-center">
              <div class="text-[15px] font-medium">精调</div>
              <div class="text-sm text-[#737373] ml-2">
                局部修改仅支持操作（3天）内的生成结果
              </div>
            </div>
            <div class="flex mt-3">
              <div
                class="w-28 h-8 leading-8 bg-[#23252F] rounded-md mr-2 cursor-pointer select-none"
                :class="
                  isMoreThanThree && 'cursor-not-allowed pointer-events-none'
                "
                @click="
                  $emit('modify', {
                    id: result.id,
                    index: activeImage,
                    mode: 'local',
                    imgUrl: result.itemList[activeImage].originUrlVisit
                  })
                "
              >
                局部修改
              </div>
              <div
                class="w-28 h-8 leading-8 bg-[#23252F] rounded-md mr-2 cursor-pointer select-none"
                :class="
                  isMoreThanThree && 'cursor-not-allowed pointer-events-none'
                "
                @click="
                  $emit('modify', {
                    id: result.id,
                    index: activeImage,
                    mode: 'access',
                    imgUrl: result.itemList[activeImage].originUrlVisit
                  })
                "
              >
                配件选择
              </div>
              <div
                class="w-28 h-8 leading-8 bg-[#23252F] rounded-md mr-2 cursor-pointer select-none"
                :class="
                  isMoreThanThree && 'cursor-not-allowed pointer-events-none'
                "
                @click="
                  $emit('modify', {
                    id: result.id,
                    index: activeImage,
                    mode: 'color',
                    imgUrl: result.itemList[activeImage].originUrlVisit
                  })
                "
              >
                配色修改
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-around py-4">
          <div
            class="flex flex-col justify-center items-center cursor-pointer"
            @click="$emit('regenerator')"
          >
            <img src="@/assets/regenerator.svg" alt="" />
            <div class="text-sm font-medium mt-1">重新生成</div>
          </div>
          <div class="flex flex-col justify-center items-center cursor-pointer">
            <img src="@/assets/backEdit.svg" alt="" />
            <div class="text-sm font-medium mt-1">返回编辑</div>
          </div>
          <div
            class="w-[544px] h-11 rounded-3xl bg-[#54C752] text-center leading-10 text-center font-bold cursor-pointer"
            @click="$emit('save')"
          >
            保存
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    isWait: {
      type: Boolean,
      default: false
    },
    couponInfo: {
      type: Object,
      default: () => {}
    },
    result: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    isWait: function (val) {
      if (val) {
        this.progress = 0
        this.progressInterval = setInterval(() => {
          this.progress += 1
          if (this.progress >= 99) {
            clearInterval(this.progressInterval)
          }
        }, 1000)
      } else {
        clearInterval(this.progressInterval)
      }
    }
  },
  data() {
    return {
      popoverVisible: false,
      activeImage: 0,
      progress: 0,
      progressInterval: null
    }
  },
  computed: {
    // 是否超过三天
    isMoreThanThree: function () {
      return dayjs(this.result.updateTime).diff(dayjs(), 'day') > 3
    }
  }
}
</script>

<style>
</style>