<template>
  <div>
    <layout
      leftContentName="参数设置"
      @generator="generator"
      @revocation="revocation"
      @regenerator="regenerator"
      @save="save"
      @update="update"
      :couponInfo="couponInfo"
      :result="taskInfo"
      :isWait="isWait"
    >
      <template slot="firstTag">
        <div class="p-4" v-loading="fileLoading || autoloading">
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">
              鞋面描述（选填）
            </div>
            <div class="flex">
              <ElInput
                v-model="instep"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你想要生成的鞋面的内容，支持中英文。"
                resize="none"
              >
              </ElInput>
              <div class="ml-2">
                <div
                  class="w-10 min-h-10 border mt-2 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="openLexicon('鞋面描述', '鞋面')"
                >
                  鞋面词库
                </div>
                <div
                  class="w-10 min-h-10 leading-10 border mt-4 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="autogenerator('鞋面')"
                >
                  智能
                </div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">
              风格描述（选填）
            </div>
            <div class="flex">
              <ElInput
                v-model="style"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你想要生成的风格的内容，支持中英文。"
                resize="none"
              >
              </ElInput>
              <div class="ml-2">
                <div
                  class="w-10 min-h-10 border mt-2 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="openLexicon('风格描述', '风格')"
                >
                  风格词库
                </div>
                <div
                  class="w-10 min-h-10 leading-10 border mt-4 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="autogenerator('风格')"
                >
                  智能
                </div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">
              外观描述（选填）
            </div>
            <div class="flex">
              <ElInput
                v-model="apperance"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你想要生成的外观的内容，支持中英文。"
                resize="none"
              >
              </ElInput>
              <div class="ml-2">
                <div
                  class="w-10 min-h-10 border mt-2 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="openLexicon('外观描述', '外观')"
                >
                  外观词库
                </div>
                <div
                  class="w-10 min-h-10 leading-10 border mt-4 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="autogenerator('外观')"
                >
                  智能
                </div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">
              功能描述（选填）
            </div>
            <div class="flex">
              <ElInput
                v-model="functions"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你想要生成的功能的内容，支持中英文。"
                resize="none"
              >
              </ElInput>
              <div class="ml-2">
                <div
                  class="w-10 min-h-10 border mt-2 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="openLexicon('功能描述', '功能')"
                >
                  功能词库
                </div>
                <div
                  class="w-10 min-h-10 leading-10 border mt-4 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="autogenerator('功能')"
                >
                  智能
                </div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">
              鞋底描述（选填）
            </div>
            <div class="flex">
              <ElInput
                v-model="bottom"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你想要生成的鞋底的内容，支持中英文。"
                resize="none"
              >
              </ElInput>
              <div class="ml-2">
                <div
                  class="w-10 min-h-10 border mt-2 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="openLexicon('鞋底描述', '鞋底')"
                >
                  鞋底词库
                </div>
                <div
                  class="w-10 min-h-10 leading-10 border mt-4 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="autogenerator('鞋底')"
                >
                  智能
                </div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div v-for="item in userDescribe" :key="item.id">
            <div class="text-left text-[#666666] text-sm mb-3">
              {{ item.classifyName }}（选填）
            </div>
            <div class="flex">
              <ElInput
                v-model="trends[item.id]"
                type="textarea"
                :rows="5"
                maxlength="1000"
                showWordLimit
                placeholder="输入你想要生成的鞋底的内容，支持中英文。"
                resize="none"
              >
              </ElInput>
              <div class="ml-2">
                <div
                  class="w-10 min-h-10 border mt-2 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="openLexicon(item.classifyName, item.id)"
                >
                  {{ item.classifyName }}词库
                </div>
                <div
                  class="w-10 min-h-10 leading-10 border mt-4 rounded-md cursor-pointer border-[#AFAFAF] bg-[#F4F5F7] text-[#666666]"
                  @click="autogenerator(item.id)"
                >
                  智能
                </div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>
          <div class="cursor-pointer" @click="describeDialogVisible = true">
            创建自定义描述
          </div>
          <el-divider></el-divider>
          <div>
            <div class="flex">
              <div class="flex flex-col items-start">
                <div class="text-left text-[#666666] text-sm">添加参考图</div>
                <div class="w-full mt-2 flex justify-start">
                  <el-upload
                    action="https://www.sheji-ai.cc/systemApi/files-anon"
                    :on-success="handleFileUpload"
                    :before-upload="checkFile"
                    :show-file-list="false"
                    :size="'40%'"
                  >
                    <div
                      class="w-28 h-28 rounded-lg flex justify-center items-center bg-[#F4F5F7]"
                      v-if="JSON.stringify(images) === '{}'"
                    >
                      <img src="@/assets/photo.svg" alt="" />
                    </div>
                    <div class="w-28 h-28 rounded-lg relative" v-else>
                      <div>
                        <img
                          :src="images.url"
                          class="w-28 h-28 rounded-lg"
                          alt=""
                        />
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>
              <div class="flex flex-1 flex-col items-start ml-4">
                <div class="text-left text-[#666666] text-sm mb-3">
                  色彩选择（选填）
                </div>
                <div class="flex">
                  <el-popconfirm
                    v-for="(item, index) in colors"
                    :key="item"
                    title="确定删除该颜色吗？"
                    @confirm="removeColor(index)"
                  >
                    <div
                      slot="reference"
                      class="w-14 h-14 rounded-lg mr-1 mb-1 cursor-pointer"
                      :style="{ background: item }"
                    ></div>
                  </el-popconfirm>

                  <div
                    class="w-14 h-14 rounded-lg bg-[#F0F0F0] flex items-center justify-center cursor-pointer"
                    @click="colorVisiable = true"
                  >
                    <img src="@/assets/plus.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-full flex items-center mt-3"
              v-if="JSON.stringify(images) !== '{}'"
            >
              <div class="w-[100px] text-sm font-light mr-3">参考图权重</div>
              <div class="w-full">
                <el-slider v-model="weight"></el-slider>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <basicSetting @update="updateSetting" />
        </div>
      </template>
    </layout>
    <colorSelector v-model="colorVisiable" @update="addColor"></colorSelector>
    <describeDialog
      v-model="describeDialogVisible"
      @update="updateUserDescribe"
    />
    <Lexicon
      v-model="showLexicon"
      :id="lexiconId"
      :type="lexiconType"
    ></Lexicon>
  </div>
</template>
      
      <script>
import {
  createJob,
  generateAutonomouslyMjContent,
  getgeneratorDetail
} from '@/api/workbench'
import { getUserDescribe } from '@/api/work'
import layout from '../components/layout'
import colorSelector from '../components/colorSelector'
import conditionSelector from '../components/conditionSelector'
import describeDialog from '../components/describeDialog'
import basicSetting from '../components/basicSetting'
import Lexicon from '../components/Lexicon'
import mixins from '../mixin'
export default {
  mixins: [mixins],
  methods: {
    /** 生成 */
    async generator() {
      const extend = {
        鞋面: this.instep ? `${this.instep}` : undefined,
        风格: this.style ? `${this.style}` : undefined,
        外观: this.appearance ? `${this.appearance}` : undefined,
        功能: this.functions ? `${this.functions}` : undefined,
        鞋底: this.bottom ? `${this.bottom}` : undefined,
        color: this.colors.length > 0 ? this.colors : undefined
      }

      Object.keys(this.trends).forEach((item) => {
        extend[item] = this.trends[item]
      })

      const params = {
        taskType: 7,
        reversePrompt: this.reversePrompt,
        aspectRatio: this.ratio,
        modelTheme: this.activeModel.id,
        isFast: this.mode,
        level: 2,
        referenceImgUrl:
          JSON.stringify(this.images) !== '{}'
            ? `${this.images.url}::${(this.weight * 2) / 100}`
            : undefined,
        extend: JSON.stringify(extend)
      }
      this.isWait = true
      const result = await createJob(params)
      if (!result) {
        this.isWait = false
      }
      // 开启轮询
      if (result.data) {
        this.taskId = result.data
        this.getDetail(result.data)
      }
    },
    // 随机生成词库
    async autogenerator(moduleName) {
      let interval
      const _loop = async () => {
        const r = await getgeneratorDetail(result.msg)
        if (r.data.state != 1) {
          clearInterval(interval)
          this.autoloading = false
          if (r.data.state == 2) {
            this.$message.success('生成成功')
            if (this[Map[moduleName]]) {
              this[Map[moduleName]] = r.data.responseCnContent
            } else {
              this.trends[moduleName] = r.data.responseCnContent
            }
            return
          }
          this.$message.error('智能生成失败')
        }
      }
      const Map = {
        鞋面: 'instep',
        风格: 'style',
        外观: 'appearance',
        功能: 'functions',
        鞋底: 'bottom'
      }
      this.autoloading = true
      const result = await generateAutonomouslyMjContent({
        moduleName,
        userContent: this[Map[moduleName]]
      })
      interval = setInterval(_loop, 1000)
    },
    // 获取用户描述分类
    async getUserDescribeMethod() {
      const result = await getUserDescribe()
      if (result) {
        this.userDescribe = result.data
        this.insertData()
      }
    },
    async updateUserDescribe(val) {
      await this.getUserDescribeMethod()
      const index = this.userDescribe.findIndex((item) => item.id === val.id)
      if (index == -1) {
        this.userDescribe.push(val)
        this.insertData()
      }
    },
    insertData() {
      this.userDescribe.forEach((item) => {
        this.$set(this.trends, item.id, '')
      })
    },
    openLexicon(name, id) {
      this.showLexicon = true
      this.lexiconId = id
      this.lexiconType = name
    }
  },
  data() {
    return {
      instep: '',
      style: '',
      apperance: '',
      functions: '',
      bottom: '',
      autoloading: false,
      showLexicon: false,
      lexiconType: '',
      lexiconId: '',
      describeDialogVisible: false,
      userDescribe: [],
      trends: {}
    }
  },
  components: {
    layout,
    colorSelector,
    conditionSelector,
    basicSetting,
    describeDialog,
    Lexicon
  },
  mounted() {
    this.getUserDescribeMethod()
  }
}
</script>
      
  <style lang="scss" scoped>
::v-deep .el-textarea__inner {
  border: 0;
  background: #f4f5f7;
}
::v-deep .el-textarea .el-input__count {
  color: #000;
  background: #f4f5f7;
}
::v-deep .el-slider__bar {
  background: #54c752;
}
::v-deep .el-slider__button {
  border-color: #54c752;
}
.conditionActive {
  background: #fff;
  border: 1px solid #54c752;
}
</style>