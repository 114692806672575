<template>
  <div>
    <el-row :gutter="16" class="h-[380px]">
      <el-col :span="14" :xl="14" class="h-[380px]">
        <el-carousel height="380px" indicator-position="inside">
          <el-carousel-item v-for="item in imgsList" :key="item.id">
            <img
              :src="item.slideshowImg"
              alt=""
              class="w-full h-[380px] select-none rounded-xl"
            />
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="6" :xl="6" class="flex flex-col justify-around h-[380px]">
        <img
          src="@/assets/home/WechatIMG298.png"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
        <img
          src="@/assets/home/WechatIMG826.png"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
        <img
          src="@/assets/home/3.svg"
          class="cursor-pointer w-full h-[140px] object-fill"
        />
      </el-col>
      <el-col :span="4" :xl="4" class="min-w-[283px]">
        <userCard></userCard>
      </el-col>
    </el-row>

    <sortHeader :searchKeys="[]"></sortHeader>

    <div class="grid grid-cols-5">
      <div
        v-for="(item, index) in informationList"
        :key="index"
        class="w-80 h-72 cursor-pointer relative"
        @click="$router.push(`/information/details/${item.id}`)"
      >
        <!-- 资讯图片数 后期加 -->
        <!-- <div
          class="w-12 h-6 bg-[rgba(0,0,0,.5)] rounded-lg text-white absolute top-4 left-3"
        >
          10图
        </div> -->
        <img :src="item.coverImg.url" alt="" class="w-80 h-60 rounded-xl" />
        <div
          class="w-full text-ellipsis overflow-hidden whitespace-nowrap text-left mt-3"
        >
          {{ item.informationTitle }}
        </div>
        <div class="mt-1 font-light text-sm text-left">
          {{ item.createTime | formatTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import sortHeader from '@/components/sortHeader'
import { getSlideShowList } from '@/api/common'
import { getImformationList } from '../../api/information'
import userCard from '../home/component/userCard.vue'
export default {
  components: {
    sortHeader
  },
  data() {
    return {
      imgsList: [],
      informationList: []
    }
  },
  components: {
    userCard,
    sortHeader
  },
  filters: {
    formatTime(val) {
      return dayjs(val).format('YY-MM-DD')
    }
  },
  methods: {
    // 获取轮播图
    async getImgs() {
      const result = await getSlideShowList(1)
      this.imgsList = result?.rows || []
    },
    // 获取资讯
    async getList() {
      const result = await getImformationList({
        page: 1,
        limit: -1
      })
      this.informationList = result.rows
    }
  },
  mounted() {
    this.getImgs()
    this.getList()
  }
}
</script>

<style>
</style>