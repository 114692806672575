<template>
  <el-drawer
    :visible.sync="value"
    :before-close="handleClose"
    @open="initData"
    :wrapperClosable="false"
    :destroy-on-close="true"
  >
    <div class="w-full flex px-2 overflow-x-auto overflow-y-hidden scrollbar">
      <div
        v-for="item in categoryList"
        :key="item.id"
        class="min-w-10 mr-4 cursor-pointer text-xl pb-2"
        :class="activeCategory == item.id ? 'active' : ''"
        @click="selectCategory(item)"
      >
        {{ item.categoryName }}
      </div>
    </div>
    <div class="flex flex-wrap p-4 mt-4">
      <div
        class="px-4 py-2 bg-[#F4F5F7] border mr-4 mb-4 rounded-3xl select-none cursor-pointer"
        :class="
          selectChilds.findIndex((items) => items.id === item.id) !== -1
            ? 'childActive'
            : ''
        "
        v-for="item in childList"
        :key="item.id"
        @click="selectChild(item)"
      >
        {{ item.instructName }}
      </div>
    </div>
    <div class="flex justify-center">
      <el-button>重置</el-button>
      <el-button type="primary" @click="handleConfirm">确认</el-button>
    </div>
  </el-drawer>
</template>
  
  <script>
import { categoryList } from '@/api/workbench'
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    selectionData: {
      type: Array,
      default: () => []
    },
    // 1随机 2品类 3场景
    type: {
      type: Number,
      default: 1
    }
  },
  computed: {
    titles() {
      const map = ['随机', '品类', '场景']
      console.log(`${map[this.type + 1]}选择`)
      return `${map[this.type + 1]}选择`
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  methods: {
    selectCategory(item) {
      this.activeCategory = item.id
      this.childList = item.distList
    },
    async selectChild(item) {
      // 判断当前是否选择
      const index = this.selectChilds.findIndex((items) => items.id === item.id)
      // 判断当前分类选中数量是否大于1
      const length = this.selectChilds.filter(
        (item) => item.categoryId === this.activeCategory
      ).length
      if (length >= 1) {
        const indexs = this.selectChilds.findIndex(
          (items) => items.categoryId === this.activeCategory
        )
        this.selectChilds.splice(indexs, 1)
      }
      // 请求数据
      const result = await this.getData(this.activeCategory, item.id)
      if (index === -1) {
        if (this.type === 1) {
          this.selectChilds = [item]
          return
        }
        this.selectChilds.push(item)
        result.data.forEach((res) => {
          console.log(res.id, this.categoryList)
          if (
            this.categoryList.findIndex((cat) => {
              console.log(cat, res.id)
              return cat.id == res.id
            }) === -1
          ) {
            this.categoryList.push(res)
          }
        })
        this.sortCategory()
      }
    },
    // 排序
    sortCategory() {
      this.categoryList.sort((a, b) => {
        return a.sort - b.sort
      })
    },
    handleClose() {
      this.value = false
      this.selectChilds = []
      this.activeCategory = -1
      this.categoryList = []
      this.childList = []
    },
    handleConfirm() {
      // 触发父组件事件
      this.$emit('update', this.selectChilds)
      // 关闭抽屉
      this.value = false
    },
    getData(parentId = -1, parentItemId) {
      return categoryList({
        moduleCode: this.type,
        parentId: parentId,
        parentItemId
      })
    },
    async initData() {
      const result = await this.getData()
      this.categoryList = result.data
      this.sortCategory()
      this.activeCategory = this.categoryList[0].id
      this.childList = this.categoryList[0].distList
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      categoryList: [],
      childList: [],
      activeCategory: -1,
      selectChilds: this.selectionData
    }
  }
}
</script>
  
<style scoped lang="scss">
.active {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 2px;
    display: block;
    border-radius: 10px;
    width: 20px;
    height: 4px;
    background: #54c752;
  }
}
.childActive {
  background: #fff;
  border: 1px solid #54c752;
}
::v-deep .el-button {
  @apply w-56 rounded-3xl;
  background: #f4f5f7;
  color: #000000;
  &:hover {
    background: #f4f5f7;
    color: #000000;
  }
}

::v-deep .el-button--primary {
  background: #54c752;
  color: #ffffff;
  border: #54c752;
  &:hover {
    background: #54c752;
    color: #ffffff;
    border: #54c752;
  }
}

.scrollbar::-webkit-scrollbar {
  width: 1px; /*  设置纵轴（y轴）轴滚动条 */
  height: 6px;
  padding-top: 4px;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  background: #727272;
}
.scrollbar::-webkit-scrollbar-track {
  background: #fff;
}
</style>