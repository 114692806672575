<template>
  <div>
    <el-dialog :visible.sync="value" append-to-body :show-close="false">
      <div class="-mt-4">
        <div class="flex items-center">
          <div
            class="w-16 h-8 leading-7 border text-center rounded-2xl bg-[#D8D8D8] text-black cursor-pointer"
          >
            返回
          </div>
          <div class="ml-3 text-2xl text-black">电力券记录</div>
        </div>
        <div class="my-4 flex text-lg">
          <div
            class="cursor-pointer"
            :class="type === '全部' && 'border-b-2 border-[#0033FF]'"
            @click="type = '全部'"
          >
            全部
          </div>
          <div
            class="ml-4 cursor-pointer"
            :class="type === '获得' && 'border-b-2 border-[#0033FF]'"
            @click="type = '获得'"
          >
            获得
          </div>
          <div
            class="ml-4 cursor-pointer"
            :class="type === '消耗' && 'border-b-2 border-[#0033FF]'"
            @click="type = '消耗'"
          >
            消耗
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      type: '全部',
      info: []
    }
  }
}
</script>
  
  <style lang="scss" scoped>
</style>
