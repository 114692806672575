<template>
  <div>
    <div class="discover_bg">
      <div class="flex justify-between">
        <div class="flex items-center">
          <img
            src="@/assets/home/1.svg"
            alt=""
            class="w-10 h-10 rounded-full"
          />
          <span class="text-white ml-4">TESTNAME</span>
          <div
            class="w-16 h-6 text-center bg-[#54C752] rounded-xl text-white ml-4 text-sm leading-6 cursor-pointer"
          >
            +关注
          </div>
        </div>
        <div class="flex w-14 h-6 bg-[#FFF] rounded-xl justify-between p-1">
          <img src="@/assets/electron.svg" alt="" class="cursor-pointer" />
          <img src="@/assets/share.svg" alt="" class="cursor-pointer" />
        </div>
      </div>
      <div class="flex">
        <div
          class="w-16 h-6 text-center bg-[#000000] rounded font-extrabold italic text-white leading-6 cursor-pointer"
        >
          合集
        </div>
        <div class="ml-4 font-extrabold text-white">这里是合集标题</div>
      </div>
      <div class="flex text-white font-medium text-left">
        这里是标题内容内扩散看错你卡上内存卡兰斯卡斯客户反馈参考了刷卡积分凯撒回复啊苏富比卡上播放·传达室可参考的女卡死你快餐式看错你看看电视看多了你卡死了你的卡上看的那款是农村扣篮大赛能看出那里可能除了看书课程女除了看来从哪里弄错了卡车考虑
      </div>
    </div>

    <div></div>

    <div class="grid grid-cols-6 gap-3 mt-4">
      <div class="w-72">
        <img src="@/assets/home/1.svg" alt="" class="w-72 rounded-xl" />,
        <div
          class="flex w-72 h-16 flex-col justify-center items-center bg-[#54C752] text-white rounded-3xl cursor-pointer"
        >
          <span class="text-2xl font-black">一键同款</span>
          <div class="flex text-sm">
            （消耗 <img src="@/assets/electron.svg" /> -50电力券）
          </div>
        </div>
      </div>
    </div>

    <comments></comments>
  </div>
</template>

<script>
import comments from '@/components/comments'
export default {
  components: {
    comments
  }
}
</script>

<style lang="scss" scoped>
.discover_bg {
  @apply w-full h-52 rounded-xl flex flex-col justify-around px-6 py-1;
  background: url('@/assets/discoverDetailbg.jpg') no-repeat;
  background-size: cover;
}
</style>