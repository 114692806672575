<template>
  <div class="flex justify-center">
    <div class="w-4/5">
      <div class="flex justify-between">
        <div class="text-2xl font-bolder">我的创作</div>
        <div>
          <el-input
            prefix-icon="el-icon-search"
            v-model="search"
            placeholder="请输入要搜索的内容"
            @change="getImgs"
          ></el-input>
        </div>
      </div>
      <div class="mt-4">
        <ElEmpty description="暂无数据" v-if="imgList.length === 0"></ElEmpty>
        <waterfall :col="6" :data="imgList" v-else>
          <template>
            <div
              class="mt-2 w-[226px] rounded-xl cursor-pointer group"
              v-for="(item, index) in imgList"
              :key="index"
              @click="
                $router.push(`/myLibrary/detail/${item.id}/${item.jobId}`)
              "
            >
              <div class="relative">
                <img :src="item.imgUrl" class="rounded-xl" />
              </div>
              <div class="mt-2 text-lg text-left" v-if="item.title">
                {{ item.title }}
              </div>
              <!--            <div-->
              <!--              class="text-sm border border-[#54C752] w-14 text-[#54C752] rounded mt-2"-->
              <!--            >-->
              <!--              {{ item.tag }}-->
              <!--            </div>-->
            </div>
          </template>
        </waterfall>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyLibrary } from '@/api/library'

export default {
  data() {
    return {
      imgList: [],
      search: '',
      page: 1,
      limit: 15
    }
  },
  methods: {
    async getImgs() {
      const result = await getMyLibrary({
        limit: this.limit,
        page: this.page,
        prompt: this.search
      })
      if (result) {
        this.imgList = result.rows
      }
    }
  },
  mounted() {
    this.getImgs()
  }
}
</script>

<style>
</style>