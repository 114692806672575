<template>
  <div>
    <ElEmpty description="该页面不存在"></ElEmpty>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$router.replace('/')
    }, 1500)
  }
}
</script>

<style>
</style>