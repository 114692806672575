import service from "@/utils/require"

/** 获取轮播图 */
export const getSlideShowList = (type) => service.get("/slideshow/list", {
    params: {
        isShow: 1,
        page: 1,
        limit: -1,
        plateId: 1,
        classifyType: type
    }
})