<template>
  <div>
    <el-dialog
      @open="init"
      title="自定义描述分类"
      :visible.sync="value"
      width="400px"
    >
      <div class="w-full flex flex-col items-center">
        <el-select placeholder="选择往期分类" v-model="activeDescribe">
          <el-option
            v-for="item in allDescribe"
            :key="item.id"
            :label="item.classifyName"
            :value="item.id"
          ></el-option>
        </el-select>
        <div class="mt-4">
          <el-radio :label="1" v-model="isShow">固定显示在参数设置</el-radio>
          <el-radio :label="1" v-model="isTop">置顶</el-radio>
        </div>
        <div class="mt-6 cursor-pointer" @click="addDialog = true">
          创建新描述分类
        </div>
      </div>
      <el-dialog
        title="创建新描述分类"
        :visible.sync="addDialog"
        append-to-body
        width="400px"
        destory-on-close
      >
        <div class="w-full flex flex-col items-center">
          <el-input placeholder="在此输入分类名称" v-model="newDescribe" />
          <div class="mt-4">
            <el-radio :label="1" v-model="addIsShow"
              >固定显示在参数设置</el-radio
            >
            <el-radio :label="1" v-model="addIsTop">置顶</el-radio>
          </div>
          <span slot="footer" class="flex mt-4">
            <div
              class="w-96 h-8 leading-8 ml-2 text-center bg-[#54C752] text-white rounded-lg cursor-pointer"
              @click="addDescribe"
            >
              保存
            </div>
          </span>
        </div>
      </el-dialog>
      <span slot="footer" class="flex">
        <div
          class="w-24 h-8 leading-8 text-center border ml-2 cursor-pointer rounded-lg"
          @click="
            manageVisiable = true
            value = false
          "
        >
          管理
        </div>
        <div
          class="w-56 h-8 leading-8 ml-2 text-center bg-[#54C752] text-white rounded-lg cursor-pointer"
          @click="saveDescribe"
        >
          保存
        </div>
      </span>
    </el-dialog>
    <el-drawer :show-close="false" :visible.sync="manageVisiable" size="450px">
      <div class="text-lg w-full text-left p-2 border-b border-[#F7F7F7]">
        <i
          class="el-icon-close mr-4 cursor-pointer"
          @click="manageVisiable = false"
        ></i>
        描述分类管理
      </div>
      <div
        class="w-full text-left p-4 px-6 border-b border-[#F7F7F7]"
        v-for="item in allDescribe"
        :key="item.id"
      >
        <span v-if="!item.edit">{{ item.classifyName }}</span>
        <el-input
          v-else
          style="width: 300px"
          v-model="item.classifyName"
          placeholder="请输入新名称"
          @keyup.enter.native="editDescribe(item)"
        ></el-input>
        <el-dropdown>
          <i class="el-icon-more rotate-90 ml-4"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="item.edit = true"
              >重命名</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="setShowMethod(item, item.isShow == 0 ? 1 : 0)"
              >{{
                item?.isShow == 0 ? '固定显示' : '取消固定'
              }}</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="setTopMethod(item, item.isTop == 0 ? 1 : 0)"
              >{{ item?.isTop == 0 ? '置顶' : '取消置顶' }}</el-dropdown-item
            >
            <el-dropdown-item @click.native="deleteDescribe(item)"
              >删除</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="mt-4 cursor-pointer" @click="addDialog = true">
        创建新描述分类
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getUserAllDescribe,
  addUserDescribe,
  delUserDescribe,
  editUserDescribe,
  setTop,
  setShow
} from '@/api/work'
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      addDialog: false,
      manageVisiable: false,
      newDescribe: '',
      allDescribe: [],
      activeDescribe: {},
      isShow: 0,
      isTop: 0,
      addIsShow: 0,
      addIsTop: 0
    }
  },
  methods: {
    /** 获取所有描述分类 */
    async getAllDescribe() {
      const result = await getUserAllDescribe()
      if (result) {
        this.allDescribe = result.data.map((item) => ({ edit: false, ...item }))
      }
    },
    /** 添加描述分类 */
    async addDescribe() {
      if (!this.newDescribe) {
        this.$message.error('请输入分类名称')
        return
      }
      const result = await addUserDescribe({
        classifyName: this.newDescribe,
        isTop: this.addIsTop,
        isShow: this.addIsShow
      })
      if (result) {
        this.$message.success('添加成功')
        this.newDescribe = ''
        this.addIsShow = 0
        this.addIsTop = 0
        this.addDialog = false
        this.init()
      }
    },
    /** 删除描述分类 */
    async deleteDescribe(item) {
      this.$confirm(
        '删除该描述分类，当前分类下所关联的词库将被一并删除！该操作无法恢复！',
        '操作提示',
        {
          confirmButtonText: '确认删除',
          cancelButtonText: '我再想想',
          type: 'warning'
        }
      ).then(async () => {
        const result = await delUserDescribe(item.id)
        if (result) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.init()
        }
      })
    },
    /** 编辑描述分类 */
    async editDescribe(item) {
      const result = await editUserDescribe({
        classifyName: item.classifyName,
        isTop: item.isTop,
        isShow: item.isShow,
        id: item.id
      })
      if (result) {
        this.$message.success('修改成功')
        item.edit = false
        this.init()
      }
    },
    /** 固定显示 */
    async setShowMethod(item, isShow) {
      const result = await setShow(item.id, isShow)
      if (result) {
        this.$message.success('修改成功')
        this.init()
      }
    },
    /** 置顶 */
    async setTopMethod(item, isTop) {
      const result = await setTop(item.id, isTop)
      if (result) {
        this.$message.success('修改成功')
        this.init()
      }
    },
    saveDescribe() {
      this.setShowMethod(this.activeDescribe, this.isShow)
      this.setTopMethod(this.activeDescribe, this.isTop)
      const result = this.allDescribe.find(
        (item) => item.id === this.activeDescribe
      )
      this.$emit('update', {
        ...result,
        isTop: this.isTop,
        isShow: this.isShow
      })
      console.log(result)
      this.value = false
    },
    init() {
      this.getAllDescribe()
    }
  }
}
</script>

<style>
</style>