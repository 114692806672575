<template>
  <div class="px-52 py-3">
    <div class="flex items-center">
      <div class="w-14 h-6 rounded text-white bg-black">资讯</div>
      <div class="ml-4 text-lg font-bold">这里是图册类内容标题</div>
    </div>
    <div class="flex justify-start mt-4">
      <div
        class="min-w-16 text-center h-5 rounded-2xl px-2 bg-[#DBDBDB] text-[#5e5e5e] mr-2 leading-5"
      >
        ADIDAS
      </div>
    </div>
    <div class="grid grid-cols-6 gap-3 mt-4">
      <img
        :src="item"
        alt=""
        class="w-56 rounded-2xl cursor-pointer"
        v-for="(item, index) in imgsList"
        :key="index"
        @click="openImgList(index)"
      />
    </div>
    <ImgLists
      v-model="showImgList"
      :imgList="imgsList"
      :defaultIndex="activeIndex"
    />
  </div>
</template>

<script>
import ImgLists from '@/components/imgLists'
import bg from '@/assets/bg.png'
import { getInformationDetail } from '../../api/information'
export default {
  data() {
    return {
      imgsList: [bg, bg, bg, bg, bg, bg, bg, bg, bg],
      showImgList: false,
      activeIndex: 0
    }
  },
  components: {
    ImgLists
  },
  methods: {
    async getDetail() {
      const id = this.$route.params.id
      if (!id) {
        this.$message.error('参数错误')
        this.$router.go(-1)
        return
      }
      const result = await getInformationDetail(id)
      console.log(result)
    },
    openImgList(index) {
      this.showImgList = true
      this.activeIndex = index
      console.log(this.activeIndex)
    }
  },
  mounted() {
    this.getDetail()
  }
}
</script>

<style>
</style>