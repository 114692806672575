import service from "@/utils/require"
import { nanoid } from "nanoid"

/** 登录 */
export const loginWithOutCode = (params) => {
  return service.post("/loginWithoutCode", params)
}

/** 获取用户信息 */
export const getUserInfo = () => {
  return service.get("/getInfo", {})
}

/** 获取微信登录二维码 */
export const getWxQRCode = () => {
    return service.get("/wx/createPcLoginCode", {
      params: {
        scene: nanoid()
      }
  })
}

/** 轮询获取用户登录信息 */
export const getWxLoginState = (scene) => {
    return service.get("/wx/pcLoginState", {
        params: {
          scene
        }
   })
}