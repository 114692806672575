<template>
  <div>
    <sortHeader
      :searchKeys="sortList"
      @changeSearchKey="changeSearchKeys"
      @changeSort="changeSort"
    ></sortHeader>
    <div class="min-h-[450px] -mt-3 overflow-y-auto">
      <ElEmpty
        description="暂无数据"
        v-if="activeSearchKeys !== '-2' && articleList.length === 0"
      ></ElEmpty>
      <!-- <waterfall :col="6" :data="articleList" @loadmore="getMore" v-else>
        <template> -->
      <!-- 临时替代方案 -->
      <div class="grid views" v-else>
        <div
          class="mt-2 w-[865px] h-[376px] p-4 bg-[#2c2c2c] rounded-xl z-[900] relative col-span-3"
          v-if="activeSearchKeys == '-2'"
        >
          <div class="flex justify-start items-center mb-4">
            <div
              v-for="(item, index) in recommendList"
              :key="index"
              class="mr-4 bg-black text-[#C3D1E3] px-3 py-1 rounded cursor-pointer text-xl font-medium"
              :class="activeRecommend === item.key && 'activeRecommend'"
              @click="changeRecommend(item.key)"
            >
              {{ item.title }}
            </div>
            <div class="text-[#C3D1E3] ml-6 text-lg font-light">
              {{
                recommendList.find((item) => item.key === activeRecommend)
                  ?.slogan
              }}
            </div>
          </div>
          <div v-if="activeRecommend === 'recommend'">
            <!-- 服务商推荐 -->
            <div>
              <div
                class="w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative bg"
              >
                <div
                  class="bg-[#000] w-full h-52 rounded-xl absolute bottom-0 flex justify-center items-center"
                >
                  <div
                    class="rounded-full w-28 h-28 border border-[#F7E296] border-4 absolute left-1/2 -ml-14 -top-14"
                  >
                    <img src="@/assets/user/head.png" alt="" />
                  </div>
                  <div
                    class="mx-auto text-[#C3D1E3] absolute bottom-2 w-48 flex flex-col justify-center items-center"
                  >
                    <div class="font-bold">无名老角</div>
                    <div
                      class="mt-2 bg-[#54C752] w-16 h-6 text-white rounded-xl cursor-pointer"
                    >
                      关注
                    </div>
                    <div class="mt-2 text-xs line-clamp-2 overflow-hidden">
                      我是无名老角
                    </div>
                    <div class="flex mt-2">
                      <div
                        class="flex flex-col items-center justify-center mr-2"
                      >
                        <span class="text-sm font-bold">96</span>
                        <span class="text-xs">关注</span>
                      </div>
                      <div
                        class="flex flex-col items-center justify-center mr-2"
                      >
                        <span class="text-sm font-bold">128</span>
                        <span class="text-xs">粉丝</span>
                      </div>
                      <div
                        class="flex flex-col items-center justify-center mr-2"
                      >
                        <span class="text-sm font-bold">1053</span>
                        <span class="text-xs">获赞</span>
                      </div>
                      <div
                        class="flex flex-col items-center justify-center mr-2"
                      >
                        <span class="text-sm font-bold">103</span>
                        <span class="text-xs">发布</span>
                      </div>
                      <div
                        class="flex flex-col items-center justify-center mr-2"
                      >
                        <span class="text-sm font-bold">10</span>
                        <span class="text-xs">被同款</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeRecommend === 'hotProject'">
            <!-- 热门项目 -->
            <div
              class="hotProject max-w-[870px] flex overflow-auto scrollbar pb-2"
            >
              <div
                class="w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative mr-4 shrink-0 hotProjectChild"
                @click="$router.push(`/works/detail/${item.id}`)"
                :style="{
                  background: 'url(' + item.coverImgUrl + ')',
                  backgroundSize: 'cover'
                }"
                v-for="(item, index) in hotProjectList"
                :key="index"
              >
                <div class="flex mt-2 px-2 justify-start">
                  <img
                    src="@/assets/user/head.png"
                    alt=""
                    class="w-6 h-6 rounded-full"
                  />
                  <span class="ml-2 text-white text-sm">EVA1990</span>
                </div>
                <div class="p-2 absolute bottom-2">
                  <div
                    class="w-20 h-5 bg-black text-white text-sm text-center rounded-xl"
                  >
                    服务100次
                  </div>
                  <div
                    class="bg-[rgba(0,0,0.13,.6)] w-52 h-9 rounded-md text-lg text-white mt-2 ml-1 leading-9 cursor-pointer"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeRecommend === 'hotPage'">
            <!-- 热门笔记 -->
            <div class="hotPage">
              <div
                class="w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative"
              >
                <div class="flex mt-2 px-2 justify-start">
                  <img src="" alt="" class="w-6 h-6 rounded-full" />
                  <span class="ml-2 text-white text-sm">TESTNAME</span>
                </div>
                <div class="p-2 absolute bottom-2 line-clamp-3 text-white">
                  这里显示笔记还是看出可惜那时课程难度就卡死你才可能农村库拉索尼查看蓝色看来你吃辣看出考虑·拿出来看三层楼看似冷酷才能卢卡斯内存卡内存颗粒大女除了看到吕尼
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="w-[282px] h-[390px]" v-if="activeSearchKeys === '-2'"></div>
        <div class="w-[282px] h-[390px]" v-if="activeSearchKeys === '-2'"></div> -->
        <div
          class="mt-2 w-[275px] rounded-xl cursor-pointer group"
          v-for="(item, index) in articleList"
          :key="index"
          @click="$router.push(`/works/detail/${item.id}`)"
        >
          <div class="relative">
            <img :src="item.coverImgUrl" class="rounded-xl" />
            <div
              class="w-8 h-8 z-[999] bg-white rounded-full absolute top-4 right-4 hidden group-hover:flex cursor-pointer justify-center items-center"
              @click="atten(item)"
            >
              <img
                src="@/assets/like.svg"
                class="w-5 h-5"
                v-if="!item.isAtten"
              />
              <img src="@/assets/likeFull.svg" class="w-5 h-5" v-else />
            </div>
            <div
              class="absolute z-[999] bottom-4 w-60 rounded-[100px] h-10 leading-10 left-1/2 -ml-[120px] bg-[#54c752] text-[#fff] transition-all hidden group-hover:block"
            >
              一键同款
            </div>
            <!-- 添加遮罩层 -->
            <div
              class="absolute inset-0 bg-black opacity-0 rounded-xl transition-opacity duration-300 group-hover:opacity-35"
            ></div>
          </div>
          <div class="mt-2 text-lg text-left" v-if="item.title">
            {{ item.title }}
          </div>
          <!--            <div-->
          <!--              class="text-sm border border-[#54C752] w-14 text-[#54C752] rounded mt-2"-->
          <!--            >-->
          <!--              {{ item.tag }}-->
          <!--            </div>-->
        </div>
        <!-- </template>
      </waterfall> -->
      </div>
    </div>
  </div>
</template>

<script>
import sortHeader from '@/components/sortHeader'
import { getArticleClassify, findMiniList, articleAttention } from '@/api/home'
export default {
  name: 'sortName',
  data() {
    return {
      activeSearchKeys: '-2',
      activeSort: 'synthesis',
      contentLoading: true,
      activeRecommend: 'hotProject',
      sortList: [
        {
          name: '关注',
          id: '-1'
        },
        {
          name: '推荐',
          id: '-2'
        },
        {
          name: '全部',
          id: ''
        }
      ],
      articleList: [],
      hotProjectList: [],
      pageNum: 1,
      recommendList: [
        {
          title: '热门项目',
          slogan: '被同行千万次验证过的服务，良心推荐！',
          key: 'hotProject'
        },
        {
          title: '服务商推荐',
          slogan: '专业的服务商，为您的产品落地保驾护航',
          key: 'recommend'
        }
        // {
        //   title: '热门笔记',
        //   slogan: '优质高人气内容给你创作灵感',
        //   key: 'hotPage'
        // }
      ]
    }
  },
  components: {
    sortHeader
  },
  methods: {
    changeSearchKeys(val) {
      this.activeSearchKeys = val
      this.pageNum = 1
      this.getList()
    },
    changeSort(val) {
      this.activeSort = val
    },
    changeRecommend(val) {
      this.activeRecommend = val
    },
    // 获取作品列表分类
    async getClassify() {
      const result = await getArticleClassify()
      this.sortList.push(...result?.rows)
    },
    // 根据当前类型获取列表数据
    async getList(change = false) {
      const result = await findMiniList({
        page: this.pageNum,
        classifyId: this.activeSearchKeys == '-2' ? '' : this.activeSearchKeys
      })
      if (!change) {
        this.articleList = result.rows
        // 临时代码，热门项目
        if (this.activeSearchKeys === '-2') {
          this.hotProjectList = this.articleList.splice(0, 5)
        }
      } else {
        this.articleList.push(...result?.rows)
      }
    },
    // 瀑布流刷新
    async getMore() {
      this.pageNum++
      this.getList(true)
    },
    // 关注/取消关注作品
    async atten(item) {
      const result = await articleAttention(item.id)
      if (result) {
        if (!item.isAtten) {
          this.$message.success(result.msg)
          item.isAtten = true
        } else {
          this.$message.warning(result.msg)
        }
      }
    }
  },
  mounted() {
    //   window.onresize = () => {
    //     window.screen.availWidth /
    // }
    this.getClassify()
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.activeRecommend {
  background: linear-gradient(90deg, #f7e296 0%, #fffbeb 50%, #f3de94 99%);
  color: #000 !important;
}
.hotProjectChild,
.hotPage {
  &:first-child::before {
    content: '';
    position: absolute;
    display: block;
    margin-left: 160px;
    width: 60px;
    height: 60px;
    background: url('@/assets/first.svg') no-repeat;
    background-size: cover;
    z-index: 999;
  }
  &:nth-child(2):before {
    content: '';
    position: absolute;
    display: block;
    margin-left: 160px;
    width: 60px;
    height: 60px;
    background: url('@/assets/second.svg') no-repeat;
    background-size: cover;
    z-index: 999;
  }
  &:nth-child(3)::before {
    content: '';
    position: absolute;
    display: block;
    margin-left: 160px;
    width: 60px;
    height: 60px;
    background: url('@/assets/third.svg') no-repeat;
    background-size: cover;
    z-index: 999;
  }
}
.bg {
  background: url('@/assets/bg.png');
}
.scrollbar::-webkit-scrollbar {
  width: 4px; /*  设置纵轴（y轴）轴滚动条 */
  height: 4px;
  margin-top: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a8a8a8;
}
.scrollbar::-webkit-scrollbar-track {
  background: #000;
}
.views {
  grid-template-columns: repeat(6, 295px);
}
</style>
