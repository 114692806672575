import discover from "@/views/discover/index.vue"
import discoverDetail from "@/views/discover/detail.vue"

export default [{
    path: "/discover",
    name: "discover",
    component: discover,
    meta: {
      title: '设辑-发现'
    }
  },
  {
    path: "/discover/detail",
    name: "discoverDetail",
    component: discoverDetail,
    meta: {
      title: '发现详情'
    }
  }
]