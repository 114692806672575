import { Message } from "element-ui"
import router from "../router"

export const getUserInfo = () => {
  const result = localStorage.getItem("userInfo")
  if (!result || result ==="undefined") {
    Message.error("获取用户信息失败，请重新登录")
    localStorage.removeItem("authorization")
    router.replace("/login")
    return
  }
  return JSON.parse(result)
}