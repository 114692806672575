<template>
  <div>
    <canvas id="canvas" ref="canvas" width="500" height="500"></canvas>
  </div>
</template>
  
  <script>
export default {
  name: 'ClipImage',
  props: {
    imgSrc: {
      type: String,
      required: true
    },
    strokeColor: {
      type: String,
      default: '#FF0000' // 默认颜色为红色
    }
  },
  data() {
    return {
      drawing: false,
      points: [],
      img: null,
      canvas: null,
      ctx: null
    }
  },
  mounted() {
    this.canvas = this.$refs.canvas
    this.ctx = this.canvas.getContext('2d')
    this.canvas.addEventListener('mousedown', this.startDrawing)
    this.canvas.addEventListener('mousemove', this.draw)
    this.canvas.addEventListener('mouseup', this.endDrawing)

    // 加载图片并设置跨域属性
    this.img = new Image()
    this.img.crossOrigin = 'anonymous'
    this.img.onload = () => {
      this.ctx.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height)
    }
    this.img.src = this.imgSrc
  },
  methods: {
    startDrawing(e) {
      this.drawing = true
      this.points = [{ x: e.offsetX, y: e.offsetY }]
    },
    draw(e) {
      if (this.drawing) {
        this.points.push({ x: e.offsetX, y: e.offsetY })
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.ctx.drawImage(
          this.img,
          0,
          0,
          this.canvas.width,
          this.canvas.height
        ) // 重新绘制图片
        this.ctx.beginPath()
        this.points.forEach((point, index) => {
          if (index === 0) {
            this.ctx.moveTo(point.x, point.y)
          } else {
            this.ctx.lineTo(point.x, point.y)
          }
        })
        this.ctx.strokeStyle = this.strokeColor // 设置轨迹颜色
        this.ctx.stroke()
      }
    },
    endDrawing() {
      this.drawing = false
      this.ctx.closePath()
      if (this.points.length < 3) {
        this.$message.error('范围太小，请重新绘制')
        return
      }
      this.applyMask()
      this.getCroppedImageBase64()
    },
    applyMask() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.ctx.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height) // 重新绘制图片

      // 在抠图区域内绘制灰色遮罩
      this.ctx.save()
      this.ctx.beginPath()
      this.points.forEach((point, index) => {
        if (index === 0) {
          this.ctx.moveTo(point.x, point.y)
        } else {
          this.ctx.lineTo(point.x, point.y)
        }
      })
      this.ctx.closePath()
      this.ctx.clip()
      this.ctx.globalAlpha = 0.5 // 设置透明度
      this.ctx.fillStyle = 'gray' // 设置遮罩颜色
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
      this.ctx.globalAlpha = 1.0 // 恢复透明度
      this.ctx.restore()
    },
    getCroppedImageBase64() {
      // 创建一个新的Canvas元素
      const newCanvas = document.createElement('canvas')
      const newCtx = newCanvas.getContext('2d')

      // 计算抠图区域的边界
      const minX = Math.min(...this.points.map((p) => p.x))
      const minY = Math.min(...this.points.map((p) => p.y))
      const maxX = Math.max(...this.points.map((p) => p.x))
      const maxY = Math.max(...this.points.map((p) => p.y))
      const width = maxX - minX
      const height = maxY - minY

      newCanvas.width = width
      newCanvas.height = height

      // 在新的Canvas上绘制抠图区域
      newCtx.drawImage(
        this.canvas,
        minX,
        minY,
        width,
        height,
        0,
        0,
        width,
        height
      )
      const allImg = this.canvas.toDataURL('image/png')
      const base64Image = this.convertToBinary(newCanvas)
      this.$emit('update', base64Image, allImg)
    },
    convertToBinary(canvas) {
      const binaryCanvas = document.createElement('canvas')
      const binaryCtx = binaryCanvas.getContext('2d')
      binaryCanvas.width = this.canvas.width
      binaryCanvas.height = this.canvas.height

      // Draw the black background
      binaryCtx.fillStyle = 'black'
      binaryCtx.fillRect(0, 0, binaryCanvas.width, binaryCanvas.height)

      // Draw the white region based on the points collected
      binaryCtx.beginPath()
      this.points.forEach((point, index) => {
        if (index === 0) {
          binaryCtx.moveTo(point.x, point.y)
        } else {
          binaryCtx.lineTo(point.x, point.y)
        }
      })
      binaryCtx.closePath()
      binaryCtx.fillStyle = 'white'
      binaryCtx.fill()

      // Get the Base64 encoding of the binary image
      const binaryBase64Image = binaryCanvas.toDataURL('image/webp')
      return binaryBase64Image
    }
  }
}
</script>
  
  <style scoped>
canvas {
  cursor: crosshair;
}
</style>