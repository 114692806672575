<template>
  <div>
    <shejiHeader :showMenu="false"></shejiHeader>
    <div class="flex">
      <div
        class="w-60 90 border-r border-[#e5e5e5]"
        style="height: calc(100vh - 75px)"
      >
        <el-collapse v-model="activeMenu">
          <el-collapse-item
            v-for="item in menuItem"
            :key="item.name"
            :name="item.name"
          >
            <template slot="title">
              <div class="w-full flex justify-center text-base select-none">
                <img :src="item.icon" alt="" class="mr-2" />
                {{ item.title }}
              </div>
            </template>
            <div
              class="w-full text-base text-center h-12 leading-[48px] cursor-pointer"
              v-for="child in item.children"
              :key="child.title"
              @click="$router.push(`/workbench${child.path}`)"
              :class="
                currentRouter === `/workbench${child.path}` && 'routerActive'
              "
            >
              {{ child.title }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div
        class="w-full bg-[#F3F3F3] overflow-y-auto"
        style="height: calc(100vh - 75px)"
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import shejiHeader from '@/components/shejiHeader'
export default {
  components: {
    shejiHeader
  },
  data() {
    return {
      activeMenu: '1',
      menuItem: [
        {
          title: '基础版(通用)',
          icon: require('@/assets/basic.svg'),
          name: '1',
          children: [
            {
              title: '文生图',
              path: '/textgeneration'
            },
            {
              title: '图生图',
              path: '/imagegeneration'
            },
            {
              title: '图片解析',
              path: '/imageanalysis'
            }
          ]
        },
        {
          title: '专业版',
          icon: require('@/assets/pro.svg'),
          name: '2',
          children: [
            {
              title: '条件生成',
              path: '/conditiongeneration'
            },
            {
              title: '图片融合',
              path: '/fusion'
            },
            {
              title: '自主创作',
              path: '/creative'
            }
          ]
        }
      ]
    }
  },
  computed: {
    currentRouter() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-collapse {
  border: 0;
}
::v-deep .el-collapse-item__content {
  padding-top: 3px;
  padding-bottom: 8px;
}
.routerActive {
  position: relative;
  background: linear-gradient(
      101deg,
      rgba(84, 199, 82, 0.2) 4%,
      rgba(104, 228, 255, 0.2) 103%
    ),
    rgba(241, 241, 241, 0.1);
  &::after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 5px;
    height: 48px;
    background: #54c752;
  }
}
</style>