import library from "@/views/library"
import libraryDetail from "@/views/library/detail"

export default [{
    path: "/myLibrary",
    name: "myLibrary",
    component: library,
    meta: {
        title: '我的图库',
        useFooter: false
    },
},
{
    path: "/myLibrary/detail/:id/:jobId",
    name: "myLibraryDetail",
    component: libraryDetail,
    meta: {
        title: '我的图库-详情',
        useFooter: false
    }
  },
]