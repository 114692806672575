<template>
  <div class="p-4">
    <div class="flex justify-between items-center">
      <div class="text-3xl">购买电力券</div>
      <div
        class="w-20 h-8 leading-7 border border-black rounded-2xl cursor-pointer"
        @click="showList = true"
      >
        记录
      </div>
    </div>
    <div class="w-full mt-4 h-[550px] rounded-2xl bg-black p-4">
      <div
        class="bg-[rgba(77,77,77,.5)] w-full h-[300px] p-4 grid grid-cols-2 gap-3"
      >
        <div
          class="w-full h-[120px] rounded-xl bg-[#323232] cursor-pointer text-white p-4"
          :class="activeKey === item.key && 'border border-[#FF7744] !bg-black'"
          v-for="item in electronInfo"
          :key="item.key"
          @click="activeKey = item.key"
        >
          <div class="flex items-center">
            <img src="@/assets/electron.svg" class="mr-2" alt="" />
            <div class="text-xl">{{ item.count }}电力券</div>
          </div>
          <div class="mt-2 text-left">售价 ￥{{ item.price }}元</div>
          <div class="mt-2 text-left">预计可生成{{ item.pages }}张</div>
        </div>
      </div>
      <div class="mt-2 text-white h-[200px] flex justify-around items-center">
        <div>
          <img src="@/assets/bg.png" alt="" class="w-[160px] h-[160px]" />
        </div>
        <div>
          <div class="text-left">
            应付：
            <span class="text-[#ff7744] text-3xl"
              >￥{{
                electronInfo.find((item) => item.key === activeKey).price
              }}</span
            >
            <span class="text-[#ff7744] text-sm ml-2">元</span>
          </div>
          <div class="mt-4 flex justify-between items-center">
            <div class="text-sm">使用微信/支付宝扫码支付</div>
            <div
              class="text-[#9b9b9b] border border-[#9b9b9b] text-sm ml-4 px-1 cursor-pointer"
            >
              发票开具入口
            </div>
          </div>
          <div class="my-4 text-left text-sm cursor-pointer">刷新二维码</div>
          <div class="flex justify-start items-center">
            <el-checkbox v-model="agree"
              >购买即视为同意《积分充值协议》</el-checkbox
            >
          </div>
        </div>
      </div>
    </div>
    <ElectronList v-model="showList" />
  </div>
</template>

<script>
import ElectronList from './electronList.vue'
export default {
  name: 'buyElectron',
  data() {
    return {
      electronInfo: [
        {
          key: 1000,
          count: 1000,
          price: 99,
          pages: 200
        },
        {
          key: 5500,
          count: 5500,
          price: 499,
          pages: 1100
        },
        {
          key: 12000,
          count: 12000,
          price: 999,
          pages: 2400
        },
        {
          key: 25000,
          count: 25000,
          price: 1999,
          pages: 5000
        }
      ],
      activeKey: 1000,
      agree: false,
      showList: false
    }
  },
  components: {
    ElectronList
  }
}
</script>

<style>
</style>