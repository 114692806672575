<template>
  <div class="px-56 py-3 overflow-hidden">
    <div class="flex justify-between items-center">
      <div class="text-4xl font-semibold">我的电力券</div>
      <div
        class="w-28 h-10 border border-black leading-9 rounded-2xl cursor-pointer"
      >
        电力券记录
      </div>
    </div>
    <div
      class="w-full h-52 rounded-2xl border mt-8 bg-[#ffaa00] relative flex items-center text-white"
    >
      <img
        src="@/assets/electronCoin.svg"
        alt=""
        class="w-60 h-60 object-cover absolute -bottom-10 -right-10"
      />
      <div class="w-[330px] border-r border-[#C48B19]">
        <div class="text-sm">账户总电力券（张）</div>
        <div class="text-6xl font-normal mt-2">1788</div>
      </div>
      <div
        class="flex justify-around items-center w-[600px] border-r border-[#C48B19]"
      >
        <div>
          <div class="text-sm">邀请好友奖励</div>
          <div class="text-4xl font-normal mt-2">518</div>
        </div>
        <div>
          <div class="text-sm">创作收益</div>
          <div class="text-4xl font-normal mt-2">208</div>
        </div>
        <div>
          <div class="text-sm">签到奖励</div>
          <div class="text-4xl font-normal mt-2">118</div>
        </div>
      </div>
      <div class="w-[230px]">
        <div
          class="ml-14 cursor-pointer w-40 h-10 leading-9 border-2 rounded-3xl bg-[#FF7744]"
          @click="buyVisible = true"
        >
          购买电力券
        </div>
      </div>
    </div>
    <buy v-model="buyVisible"></buy>
  </div>
</template>

<script>
import buy from '../components/buy'
export default {
  components: {
    buy
  },
  data() {
    return {
      buyVisible: false
    }
  }
}
</script>

<style>
</style>