<template>
  <div v-if="value">
    <div
      class="z-[999] fixed top-0 left-0 w-full h-screen overflow-hidden bg-[rgba(0,0,0,.7)] flex justify-center items-center"
    >
      <div
        class="bg-black w-[780px] relative rounded-xl py-4"
        style="height: 680px"
      >
        <div class="close" @click="value = false">
          <img src="@/assets/close.svg" alt="" />
        </div>
        <div class="flex w-full">
          <div
            class="w-40 py-4 overflow-y-auto flex flex-col items-center scrollbar ml-4 transition-all"
            ref="scrollContent"
            style="height: 650px"
          >
            <img
              v-for="(item, index) in imgList"
              :src="item"
              :key="index"
              class="w-32 h-40 rounded-lg mb-4 cursor-pointer"
              :class="index === activeIndex ? 'border border-[#0033FF]' : ''"
              alt=""
              @click="activeIndex = index"
            />
          </div>
          <div
            class="h-[630px] w-full *:flex justify-center items-center relative"
          >
            <div
              class="w-12 h-8 leading-8 rounded-xl text-left text-white bg-[rgba(0,0,0,.5)] absolute top-6 left-20 z-50"
            >
              {{ activeIndex + 1 }} / {{ imgList.length }}
            </div>
            <div class="absolute top-6 right-16 z-50" v-if="$slots.context">
              <ElDropdown>
                <div
                  class="w-10 h-10 bg-[#D000FF] rounded-xl flex items-center justify-center cursor-pointer"
                >
                  <img src="@/assets/menu.svg" alt="" />
                </div>
                <slot name="context" slot="dropdown"></slot>
              </ElDropdown>
            </div>
            <ElImage :src="imgList[activeIndex]" class="w-[560px]"></ElImage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    imgList: {
      type: Array,
      default: () => []
    },
    defaultIndex: {
      type: Number,
      default: 0
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      activeIndex: this.defaultIndex
    }
  },
  watch: {
    defaultIndex: function (val) {
      this.activeIndex = val
      this.$nextTick(() => {
        const height = 108 * val
        this.$refs.scrollContent.scrollTo(0, height)
      })
    }
  },
  mounted() {
    if (this.imgList.length === 0) {
      this.$message.error('无图片传入！参数错误！')
      value = false
    }
  }
}
</script>

<style lang="scss">
.close {
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  right: -60px;
  top: -60px;
  cursor: pointer;
}
.scrollbar::-webkit-scrollbar {
  width: 4px; /*  设置纵轴（y轴）轴滚动条 */
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #727272;
}
.scrollbar::-webkit-scrollbar-track {
  background: #000;
}
</style>