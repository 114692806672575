<template>
  <div>
    <div class="flex flex-col items-start" @click="openSetting">
      <div>基础设置</div>
      <div class="w-full mt-2 flex justify-around">
        <div class="flex flex-col">
          <div
            class="w-28 h-16 flex flex-col justify-center items-center bg-[#F4F5F7] rounded-xl cursor-pointer"
          >
            <img src="@/assets/scale.svg" class="w-6 h-6 mt-2" alt="" />
            <span class="mt-1 text-sm">{{ ratio }}</span>
          </div>
          <div class="text-sm text-[#8F8F8F] font-normal mt-2">图片比例</div>
        </div>
        <div class="flex flex-col">
          <div
            class="w-28 h-16 ml-4 flex justify-center items-center bg-[#F4F5F7] rounded-xl cursor-pointer relative"
          >
            <div class="-mt-2">{{ activeModel.modelName }}</div>
            <img :src="activeModel.modelIcon" class="w-9 h-9 ml-2" alt="" />
          </div>
          <div class="text-sm text-[#8F8F8F] font-normal mt-2">生成模型</div>
        </div>
        <div class="flex flex-col">
          <div
            class="w-28 h-16 ml-4 flex flex-col items-center justify-center bg-[#F4F5F7] rounded-xl cursor-pointer"
          >
            <div class="text-lg font-medium text-[#666666]">
              {{ modeMap[mode].name }}
            </div>
            <div class="text-xs font-medium text-[#AAAAAA]">
              {{ modeMap[mode].des }}
            </div>
          </div>
          <div class="text-sm text-[#8F8F8F] font-normal mt-2">生成模式</div>
        </div>
      </div>
    </div>
    <el-drawer
      title="基础设置"
      :visible.sync="settingVisible"
      direction="rtl"
      :modal-append-to-body="false"
      :before-close="handleReset"
    >
      <template slot="title">
        <div class="text-xl font-bold text-black">基础设置</div>
      </template>
      <el-divider></el-divider>
      <div class="p-2">
        <div class="text-left">图片比例</div>
        <div class="flex flex-wrap">
          <div
            class="w-28 h-16 flex flex-col justify-center items-center bg-[#F4F5F7] rounded-xl cursor-pointer mr-2 mt-2"
            :class="item.value === ratio && 'border border-[#54C752]'"
            v-for="(item, index) in ratioMap"
            :key="index"
            @click="ratio = item.value"
          >
            <img src="@/assets/scale.svg" class="w-6 h-6 mt-2" alt="" />
            <span class="mt-1 text-sm">{{ item.value }}</span>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="p-2">
        <div class="text-left">模型选择</div>
        <div class="mt-3 flex">
          <div
            class="w-28 h-16 flex justify-center items-center bg-[#F4F5F7] rounded-xl cursor-pointer relative mr-2"
            :class="item.id === activeModel.id && 'border border-[#54C752]'"
            v-for="(item, index) in modelList"
            :key="index"
            @click="activeModel = item"
          >
            <div class="-mt-2">{{ item.modelName }}</div>
            <img :src="item.modelIcon" class="w-9 h-9 ml-2" alt="" />
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="p-2">
        <div class="text-left">生成模式</div>
        <div class="mt-3 flex">
          <div
            class="w-28 h-16 flex flex-col items-center justify-center bg-[#F4F5F7] rounded-xl cursor-pointer mr-2"
            :class="item.value === mode && 'border border-[#54C752]'"
            v-for="(item, index) in modeMap"
            :key="index"
            @click="mode = item.value"
          >
            <div class="text-lg font-medium text-[#666666]">
              {{ item.name }}
            </div>
            <div class="text-xs font-medium text-[#AAAAAA]">
              {{ item.des }}
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="flex justify-center items-center">
        <div
          class="w-[200px] h-[45px] leading-[45px] border text-black rounded-3xl cursor-pointer select-none"
          @click="handleReset"
        >
          重置
        </div>
        <div
          class="w-[200px] h-[45px] leading-[45px] ml-8 bg-[#54C752] text-white rounded-3xl cursor-pointer select-none"
          @click="handleConfirm"
        >
          确认
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getModelTheme } from '@/api/workbench'
export default {
  data() {
    return {
      settingVisible: false,
      ratio: '1:1',
      modelList: [],
      activeModel: {},
      mode: 1,
      modeMap: [
        {
          value: 0,
          name: '普通模式',
          des: '预计排队1~5分钟'
        },
        {
          value: 1,
          name: '快速生成',
          des: '1分钟内|5积分/次'
        }
      ],
      ratioMap: [
        {
          value: '1:1'
        },
        {
          value: '1:2'
        },
        {
          value: '4:3'
        },
        {
          value: '3:4'
        },
        {
          value: '16:9'
        },
        {
          value: '9:16'
        }
      ]
    }
  },
  methods: {
    // 获取全部模型
    async getModel() {
      const result = await getModelTheme()
      if (result) {
        this.modelList = result.rows
        this.activeModel = this.modelList[0]
      }
    },
    handleReset() {
      this.ratio = '1:1'
      this.activeModel = this.modelList[0]
      this.mode = 1
      this.handleConfirm()
    },
    handleConfirm() {
      this.$emit('update', {
        ratio: this.ratio,
        model: this.activeModel,
        mode: this.mode
      })
      this.settingVisible = false
    },
    // 打开基础设置
    openSetting() {
      this.settingVisible = true
    }
  },
  mounted() {
    this.getModel()
  }
}
</script>

<style>
</style>