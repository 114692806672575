<template>
  <!-- 未登录 -->
  <div class="w-full card" v-if="JSON.stringify(userInfo) === '{}'">
    <img
      src="@/assets/user/usercardBackground.svg"
      alt=""
      class="absolute z-0"
    />
    <img src="@/assets/logoCard.svg" alt="" class="z-50" />
    <div class="w-2/3 text-xl mt-6 font-[350px] text-white z-50">
      从AI创意到产品落地的一站式服务平台
    </div>
    <div class="z-50">
      <div
        class="w-36 h-8 bg-[#54C752] text-white font-medium z-50 leading-8 rounded-2xl mt-10 mb-8 cursor-pointer select-none"
        @click="$router.replace('/login')"
      >
        立即创作
      </div>
    </div>
    <div class="flex items-center">
      注册就送免费电力券
      <img src="@/assets/electron.svg" class="ml-2" alt="" />
    </div>
    <div class="cursor-pointer z-50 border-t pt-4 mt-4 w-11/12">
      <div class="flex items-center justify-center">
        <img :src="require(`@/assets/user/study.svg`)" alt="" class="w-5 h-5" />
        <div class="mx-3 font-bold">开始学习教程</div>
        <div>></div>
      </div>
    </div>
  </div>
  <!-- 登录 - 非会员 -->
  <div class="w-full card" v-else-if="!userInfo.isVip">
    <img
      src="@/assets/user/usercardBackground.svg"
      alt=""
      class="absolute z-0"
    />
    <img :src="userInfo.avatar" alt="" class="w-16 h-16 rounded-full z-50" />
    <div class="z-50">
      <div class="font-bold text-xl mt-3">{{ userInfo.nickName }}</div>
      <div class="mt-3 font-normal text-sm">NO:{{ userInfo.userId }}</div>
      <div class="flex mt-3 w-[236px]">
        <div class="flex-1 border-r px-4 border-[#4f4f4f]">
          <div class="font-medium text-sm text-left">加速卡次数:</div>
          <div class="font-medium text-sm text-left">
            本月
            <span class="ml-1 font-bold text-xl"
              >{{ userInfo.freeFastNumMonth || 0 }}/{{
                userInfo.freeFastNum || 0
              }}</span
            >
          </div>
        </div>
        <div class="flex-1 px-4">
          <div class="font-medium text-sm text-left">AI能力:</div>
          <div class="font-medium text-sm text-left">
            电力券
            <span class="mx-1 font-bold text-xl">{{
              userInfo.powerCoupon || 0
            }}</span
            >张
          </div>
        </div>
      </div>
      <hr class="w-[225px] my-2 border border-[#4f4f4f]" />
      <div>
        <div class="flex items-center justify-center">
          <img src="@/assets/user/member-icon.svg" alt="" />
          <div class="ml-2">开通会员享无限创意特权</div>
        </div>
        <div class="flex justify-center items-center my-3">
          <div
            class="bg-[#F0C879] w-28 h-8 leading-8 text-white rounded-2xl z-50 cursor-pointer"
          >
            立即开通
          </div>
        </div>
      </div>
      <hr class="w-[225px] my-2 border border-[#4f4f4f]" />
      <div class="cursor-pointer z-50">
        <div class="flex items-center justify-center">
          <img src="@/assets/user/study.svg" alt="" class="w-5 h-5" />
          <div class="mx-3 font-bold">开始学习教程</div>
          <div>></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 登录 - 会员 -->
  <div class="w-full card" :style="getStyle()" v-else>
    <img
      :src="require(`@/assets/user/usercardBackground-${userInfo.level}.svg`)"
      alt=""
      class="absolute z-0"
    />
    <img :src="userInfo.avatar" alt="" class="w-16 h-16 rounded-full z-50" />
    <div class="z-50 flex flex-col justify-around items-center">
      <div class="font-bold text-xl mt-3">{{ userInfo.nickName }}</div>
      <div class="mt-3 font-normal text-sm flex justify-center items-center">
        <img
          :src="require(`@/assets/user/${userInfo.level}.svg`)"
          class="w-24 h-7"
          alt=""
        />
        <div>NO:{{ userInfo.userId }}</div>
      </div>
      <div class="flex mt-3 w-full justify-center">
        <div
          class="flex-1 px-1 flex flex-col justify-center items-center"
          :style="`border-right: 1px solid ${
            typeInfo.find((item) => item.key == userInfo.level).borderColor
          }`"
        >
          <div class="w-full font-medium text-sm text-left">加速卡次数:</div>
          <div class="font-medium text-sm text-left">
            本月
            <span class="ml-1 font-bold text-lg"
              >{{ userInfo.freeFastNumMonth || 0 }}/{{
                userInfo.freeFastNum || 0
              }}</span
            >
          </div>
        </div>
        <div class="flex-1 px-3">
          <div class="font-medium text-sm text-left">AI生成次数:</div>
          <div class="font-medium text-sm text-left">
            有效期内
            <span class="mx-1 font-bold text-lg">无限</span>
          </div>
        </div>
      </div>
      <hr
        class="w-full my-2"
        :style="`border-top: 1px solid ${
          typeInfo.find((item) => item.key == userInfo.level).borderColor
        }`"
      />
      <div class="flex flex-col items-start py-3">
        <div class="flex">
          <div class="flex font-medium">鞋样设计|有效期至:</div>
          <div
            class="w-24 h-6 leading-6 rounded-2xl font-semibold text-center ml-2 text-white cursor-pointer"
            :style="`background: ${
              typeInfo.find((item) => item.key == userInfo.level).buttonbg
            }`"
          >
            立即续费
          </div>
        </div>
        <div class="w-full mt-2">
          <div class="flex">
            <img
              :src="require(`@/assets/user/member-icon-${userInfo.level}.svg`)"
              alt=""
            />
            <div class="ml-2">{{ userInfo.vipEndTime }}</div>
          </div>
        </div>
      </div>
      <hr
        class="w-full my-2"
        :style="`border-top: 1px solid ${
          typeInfo.find((item) => item.key == userInfo.level).borderColor
        }`"
      />
      <div class="cursor-pointer z-50">
        <div class="flex items-center justify-center">
          <img
            :src="require(`@/assets/user/study-${userInfo.level}.svg`)"
            alt=""
            class="w-5 h-5"
          />
          <div class="mx-3 font-bold">开始学习教程</div>
          <div>></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/user'
import { getUserInfo as getUser } from '@/api/user'
export default {
  data() {
    return {
      userInfo: {},
      typeInfo: [
        {
          name: '白银会员',
          key: '1',
          bgcolor:
            'radial-gradient(55% 55% at 13% 5%, #EDF0F1 0%, #C9D9DE 100%)',
          color: '#7B91A5',
          borderColor: '#A6B7C7',
          buttonbg: '#526679'
        },
        {
          name: '黄金会员',
          key: '2',
          bgcolor:
            'radial-gradient(55% 55% at 13% 5%, #F4EEEA 0%, #E5C9B9 100%)',
          color: '#AE8265',
          borderColor: '#C8A995',
          buttonbg: '#6A5932'
        },
        {
          name: '铂金会员',
          key: '3',
          bgcolor:
            'linear-gradient(140deg, #F7F4FF -6%, #D3BAFC 38%, #AB94FE 86%)',
          color: '#885DD6',
          borderColor: '#AC8BEA',
          buttonbg: '#7242A3'
        }
      ]
    }
  },
  methods: {
    getStyle() {
      const result = this.typeInfo.find(
        (item) => item.key == this.userInfo.level
      )
      return `background: ${result.bgcolor}; color: ${result.color}`
    }
  },
  async mounted() {
    if (localStorage.getItem('authorization')) {
      const userInfo = await getUser()
      localStorage.setItem('userInfo', JSON.stringify(userInfo.user))
      this.userInfo = getUserInfo()
    }
  }
}
</script>

<style lang="scss">
.card {
  @apply flex flex-col justify-center items-center h-[380px];
  background: radial-gradient(171% 171% at -20% -13%, #636363 0%, #000000 100%);
  position: relative;
  color: #afafaf;
  border-radius: 14px;
}
</style>