<template>
  <div>
    <div class="flex justify-center items-center py-4 border-b mt-2">
      <div
        class="mr-16 text-xl font-normal cursor-pointer select-none"
        :class="item.id === activeName ? '!font-black active_router' : ''"
        v-for="item in searchKeys"
        :key="item.id"
        @click="changeTag(item.id)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="flex justify-end my-4 font-light">
      <div
        v-for="(item, index) in sortList"
        :key="index"
        class="mr-4 cursor-pointer select-none text-[#A09E9F] sticky top-0"
        :class="
          (item.sort
            ? activeSort.includes(item.key)
            : activeSort === item.key) && 'font-black text-black'
        "
        @click="changeSort(item)"
      >
        {{ item.title }}
        <i
          class="el-icon-caret-top"
          v-if="activeSort === `${item.key}-top`"
        ></i>
        <i
          class="el-icon-caret-bottom"
          v-if="activeSort === `${item.key}-bottom`"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchKeys: {
      type: Array,
      default: () => [
        {
          name: '关注',
          id: '-1'
        },
        {
          name: '推荐',
          id: '-2'
        },
        {
          name: '全部',
          id: ''
        }
      ]
    },
    defaultSearchKey: {
      type: String,
      default: '-2'
    }
  },
  data() {
    return {
      activeName: this.defaultSearchKey,
      activeSort: 'synthesis',
      sortList: [
        {
          title: '综合',
          key: 'synthesis',
          sort: false
        },
        {
          title: '最新',
          key: 'new',
          sort: false
        },
        {
          title: '同款',
          key: 'same',
          sort: true
        },
        {
          title: '点赞',
          key: 'good',
          sort: true
        },
        {
          title: '收藏',
          key: 'collection',
          sort: true
        }
      ]
    }
  },
  methods: {
    changeTag(key) {
      this.activeName = key
      this.$emit('changeSearchKey', key)
    },
    changeSort(item) {
      if (item.sort) {
        if (this.activeSort.includes('top')) {
          this.activeSort = `${item.key}-bottom`
        } else {
          this.activeSort = `${item.key}-top`
        }
      } else {
        this.activeSort = item.key
      }
      this.$emit('changeSort', this.activeSort)
    }
  }
}
</script>

<style>
</style>