import electron from "@/views/vip/pages/electron.vue"

export default [
  {
    path: "/my/electron",
    name: "electron",
    component: electron,
    meta: {
        title: '我的电力券',
        useFooter: false
    }
  }
]