import { generatorAgain, saveJob, upscalImg } from '../../../api/workbench'
import {
    getUserGeneratorCoupon,
    getJobDetail,
    revocationCreator
} from "@/api/workbench"
export default {
    data() {
        return {
            colorVisiable: false,
            conditionVisiable: false,
            conditionType: 1,
            conditions: [],
            colors: [],
            reversePrompt: '',
            images: {},
            imageLists: [],
            weight: 50,
            isWait: false,
            fileLoading: false,
            taskId: '',
            couponInfo: {},
            taskInfo: {},
            promptCn: '',
            timer: null,
            mode: 1,
            ratio: "1:1",
            activeModel: {}
        }
    },
    methods: {
        // 轮询查询状态
        async getDetail(id, regenerator = false) {
            const _get = async () => {
                const result = await getJobDetail(id)
                if (result?.data) {
                    const status = result.data.status
                    if (!['wait_create', 'wait_finish'].includes(status)) {
                        clearInterval(this.timer)
                        if (status === 'finish_success') {
                            this.taskInfo = result.data
                            this.$message.success(regenerator ? '重新生成成功' : '生成成功！')
                        } else {
                            this.taskInfo = {}
                            this.$message.error(result.data.errorMessages)
                        }
                        this.isWait = false
                        this.timer = null
                    }
                }
            }
            if (!this.timer) {
                this.timer = setInterval(_get, 1000)
            }
        },
        // 重新生成任务
        async regenerator() {
            this.isWait = true
            const result = await generatorAgain(this.taskId)
            if (!result) {
                this.isWait = false
            }
            // 开启轮询
            if (result.data) {
                this.taskInfo = {}
                this.taskId = result.data
                this.getDetail(result.data, true)
            }
        },
        // 撤销
        async revocation() {
            const result = await revocationCreator(this.taskId)
            if (result) {
                this.$message.success('撤销成功')
                this.isWait = false
                this.taskId = ''
                clearInterval(this.timer)
            }
        },

        // 检查文件格式
        checkFile(file) {
            const isFormat =
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/jpg'
            const isLt2M = file.size / 1024 / 1024 < 200

            if (!isFormat) {
                this.$message.error('上传的图片只能是 PNG、JPG、JPEG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传的图片大小不能超过 200MB!')
            }
            const result = isFormat && isLt2M
            if (result) {
                this.fileLoading = true
                return true
            }
            return false
        },
        // 处理图片上传
        handleFileUpload(val) {
            this.$message.success('上传成功')
            this.images = { ...val }
            this.fileLoading = false
        },
        // 清除图片
        deleteImages(e) {
            this.images.splice(e, 1)
        },
        // 获取当前用户的权益信息
        async getCoupon() {
            const result = await getUserGeneratorCoupon({
                isFast: String(this.mode),
                taskType: 1
            })
            if (result) {
                this.couponInfo = result.data
            }
        },
        // 保存
        async save() {
            const result = await saveJob(this.taskId)
            if (result) {
                this.taskId = ''
                this.taskInfo = {}
                this.$message.success('保存成功!')
            }
        },
        // 升档
        async update(val) {
            const result = await upscalImg(this.taskId, val)
            if (result) {
                this.isWait = true
                this.taskInfo = {}
                this.taskId = result.data
                this.getDetail(result.data, true)
            }
        },

        // 添加颜色
        addColor(val) {
            if (this.colors.length >= 4) {
                this.$message.error('最多添加4个颜色')
                return
            }
            const index = this.colors.findIndex((item) => item === val)
            if (index > -1) {
                return
            }
            this.colors.push(val)
        },
        // 删除颜色
        removeColor(index) {
            this.colors.splice(index, 1)
        },
        // 添加条件
        updateCondition(val) {
            this.conditions = val
        },
        // 更新基础设置
        updateSetting(val) {
            this.ratio = val.ratio
            this.mode = val.ratio
            this.activeModel = val.model
        }
    },
    mounted() {
        this.getCoupon()
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id)
            return
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}