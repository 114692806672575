import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// 布局文件
import Layout from "../layout/Layout.vue";
import aiContentLayout from "../layout/AiContentLayout.vue";
// 基础页面
import Login from "@/views/login"
import Home from "@/views/home"
import discover from "./discover"
import information from "./information";
import vip from "./vip"
// 工作台
import workbench from "./workbench"
// 404
import notFound from "@/views/404"
import works from "./works";
import library from "./library";

const routes = [
    // 登录
  {
    path: "/login",
    name: "login",
        component: Login,
        meta: {
            title: '作品详情'
          }
  },
  // 普通页面
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [ 
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        title: '设辑-首页'
      }
    },
    ...discover,
    ...information,
    ...works,
    ...library,
     ...vip
    ]
    },
    // AI工作台
    {
        path: "/workbench",
        component: aiContentLayout,
        redirect: '/workbench/textgeneration',
        children: [
          ...workbench
        ]
    },
    // 404
    {
      path: "/*",
      component: notFound,
      meta: {
        title: '该页面不存在'
      }
    }
];



const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || "设辑";
    next()
});

// 下面是解决编程式导航多次点击路由时报错的方法'
//编程式导航多次点击路由时报错的原因是因为
//vue-router在3.1.0版本之后，push和replace方法会返回一个promise对象，如果不捕获异常，控制台会报错
//解决方法：在router/index.js中加入以下代码
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
    // 解释一下下面代码
    // 1.首先调用原来的push方法，将location传进去
    // 2.然后调用catch方法，捕获异常
    // 3.最后返回一个promise对象
    return originalPush.call(this, location).catch(err => err);
};
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

export default router;
