<template>
  <div>
    <shejiDialog width="900px" v-model="value">
      <div class="flex">
        <div class="w-[250px] h-[630px] pl-4 border-r relative">
          <div
            v-for="(item, index) in menuList"
            :key="index"
            class="w-full h-[48px] px-2 flex justify-start items-center cursor-pointer"
            :class="activeKey === item.key && 'routerActive'"
          >
            <img
              :src="require(`../assets/${item.icon}.svg`)"
              alt=""
              class="mr-2"
            />
            {{ item.title }}
          </div>
          <div class="flex justify-center items-center absolute bottom-0">
            <img src="@/assets/shejilogo.svg" alt="" />
          </div>
        </div>
        <div class="flex-1">
          <component :is="activeKey"></component>
        </div>
      </div>
    </shejiDialog>
  </div>
</template>

<script>
import shejiDialog from '@/components/shejiDialog'
import buyElectron from './buyElectron.vue'
export default {
  components: {
    shejiDialog,
    buyElectron
  },
  data() {
    return {
      menuList: [
        {
          title: '购买电力券',
          key: 'buyElectron',
          icon: 'electronIcon'
        }
      ],
      activeKey: this.defaultKey
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    defaultKey: {
      type: String,
      default: 'buyElectron'
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.routerActive {
  position: relative;
  background: linear-gradient(
      101deg,
      rgba(84, 199, 82, 0.2) 4%,
      rgba(104, 228, 255, 0.2) 103%
    ),
    rgba(241, 241, 241, 0.1);
  &::after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 5px;
    height: 48px;
    background: #54c752;
  }
}
</style>