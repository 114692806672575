<template>
  <div class="h-12 bg-white flex justify-center items-center">
    <ElPopover
      v-for="item in functionMap"
      :key="item.name"
      :title="item.title"
      trigger="hover"
      popper-class="w-10 bg-[#000]"
    >
      <img
        slot="reference"
        :src="item.icon"
        alt=""
        class="w-5 h-5 mr-8 cursor-pointer"
        @click="callFather(item)"
      />
    </ElPopover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      functionMap: [
        {
          name: 'share',
          icon: require('@/assets/share.svg'),
          title: '分享'
        },
        {
          name: 'download',
          icon: require('@/assets/download.svg'),
          title: '下载'
        },
        {
          name: 'delete',
          icon: require('@/assets/delete.svg'),
          title: '删除'
        },
        {
          name: 'revoke',
          icon: require('@/assets/revoke.svg'),
          title: '撤回'
        }
      ]
    }
  },
  methods: {
    callFather(item) {
      // 呼唤父亲该干活了
      this.$emit(item.name)
    }
  }
}
</script>

<style>
</style>