<template>
  <div
    class="flex flex-col justify-around items-center w-screen h-[233px] bg-[#2d2d30] text-[#c8c8c8] mt-4"
  >
    <div class="w-3/4 flex flex-col justify-around">
      <div>
        <div class="text-lg text-left"></div>
        <div class="flex flex-wrap">
          <!-- <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div>-->
          <!--          <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div>-->
          <!--          <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div>-->
          <!--          <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div>-->
          <!--          <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div>-->
          <!--          <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div>-->
          <!--          <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div>-->
          <!--          <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div>-->
          <!--          <div class="w-44 h-16 bg-white rounded-lg mt-4 mr-4"></div> -->

          <!-- <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div>
          <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div>
          <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div>
          <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div>
          <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div>
          <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div>
          <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div>
          <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div>
          <div class="w-44 h-16 rounded-lg mt-4 mr-4"></div> -->
        </div>
      </div>
<!--      <div class="flex items-baseline mt-2">-->
<!--        <div class="text-lg">友情链接</div>-->
<!--        <div class="cursor-pointer ml-2 text-sm">3D素材</div>-->
<!--        <div class="cursor-pointer ml-2 text-sm">设计大赛</div>-->
<!--        <div class="cursor-pointer ml-2 text-sm">视觉中国</div>-->
<!--        <div class="cursor-pointer ml-2 text-sm">优设</div>-->
<!--        <div class="cursor-pointer ml-2 text-sm">设计达人</div>-->
<!--        <div class="cursor-pointer ml-2 text-sm">设计导航</div>-->
<!--        <div class="cursor-pointer ml-2 text-sm">OP3D</div>-->
<!--        <div class="cursor-pointer ml-2 text-sm">优设AI导航</div>-->
<!--      </div>-->
    </div>
    <div class="flex w-96 justify-between">
      <div class="cursor-pointer">关于我们</div>
      <div class="cursor-pointer">联系我们</div>
      <div class="cursor-pointer">协议中心</div>
      <div class="cursor-pointer">官方公众号</div>
    </div>
    <div class="flex justify-between">
      ©2024 d.design. 设辑（泉州）信息科技有限公司
      <img src="@/assets/police.png" class="mx-2 h-5" />
      <!--      闽公网安备 1234567890号-->
      <a href="https://beian.miit.gov.cn/" style="color: #c8c8c8">
        闽ICP备2024060288号
      </a>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>
