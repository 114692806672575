<template>
  <el-drawer :visible.sync="value" :before-close="handleClose" title="颜色选择">
    <div class="flex justify-around">
      <div class="flex flex-wrap w-[350px]">
        <div
          v-for="item in usefulColor"
          :key="item"
          class="w-8 h-8 rounded mr-1 mb-1 cursor-pointer"
          :style="{ background: item }"
          @click="color = item"
        ></div>
      </div>
      <div class="flex flex-col items-center w-[300px]">
        <input type="color" v-model="color" />
        <div class="border w-full bg-gray-200">{{ color }}</div>
      </div>
    </div>
    <div class="flex justify-end mt-4 px-6">
      <el-button type="primary" @click="updateColor">确认</el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: ['modelValue'],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  methods: {
    handleClose() {
      this.value = false
    },
    updateColor() {
      this.$emit('update', this.color)
      this.handleClose()
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      color: '#000000',
      usefulColor: [
        '#FFB6C1',
        '#FFC0CB',
        '#DC143C',
        '#FFF0F5',
        '#DB7093',
        '#FF69B4',
        '#FF1493',
        '#C71585',
        '#DA70D6',
        '#D8BFD8',
        '#DDA0DD',
        '#EE82EE',
        '#FF00FF',
        '#8B008B',
        '#800080',
        '#BA55D3',
        '#9400D3',
        '#9932CC',
        '#4B0082',
        '#8A2BE2',
        '#9370DB',
        '#7B68EE',
        '#6A5ACD',
        '#483D8B',

        '#E6E6FA',
        '#F8F8FF',
        '#0000FF',
        '#0000CD',
        '#191970',
        '#000080',
        '#4169E1',
        '#6495ED',
        '#B0C4DE',
        '#778899',
        '#708090',
        '#1E90FF',
        '#F0F8FF',
        '#4682B4',
        '#87CEFA',
        '#87CEEB',
        '#00BFFF',
        '#ADD8E6',
        '#B0E0E6',
        '#5F9EA0',
        '#F0FFFF',
        '#E1FFFF',
        '#AFEEEE',
        '#00FFFF',
        '#00CED1',
        '#2F4F4F',
        '#008B8B',
        '#008080'
      ]
    }
  }
}
</script>

<style scoped>
input[type='color'] {
  width: 300px;
  height: 300px;
  border: none;
}
</style>