<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex justify-center">
      <div class="w-[898px]">
        <div
          class="w-full h-[692px] bg-[#F0F0F0] flex justify-center items-center relative"
        >
          <div
            class="w-12 h-6 bg-[rgba(0,0,0,.5)] rounded-2xl absolute top-4 left-4 text-white font-light"
          >
            {{ activeImage + 1 }}/{{ info.imgStorages?.length }}
          </div>
          <img
            :src="info.imgStorages?.[activeImage]?.imgUrl"
            alt=""
            class="w-[750px] h-[650px]"
          />
        </div>
        <div class="w-full h-[110px] flex justify-center">
          <div
            v-for="(item, index) in info.imgStorages"
            :key="index"
            class="w-[100px] h-[110px] mt-4 ml-4"
            :class="{ 'border-2 border-[#002BFF]': index == activeImage }"
          >
            <img
              :src="item.imgUrl"
              alt=""
              class="w-[100px] h-[100px] cursor-pointer"
              @click="activeImage = index"
            />
          </div>
        </div>
      </div>
      <div class="w-[389px] h-[237px] ml-6">
        <div class="flex items-center">
          <div>
            <img :src="info.creatorUser?.avatar" alt="" class="w-10 h-10" />
          </div>
          <div class="ml-2">{{ info.creatorUser?.userName }}</div>
          <div
            class="w-14 ml-4 text-white bg-[#54C752] rounded-lg cursor-pointer"
          >
            <div v-if="!info.isAtten" @click="followUser(true)">+关注</div>
            <div v-else @click="followUser(false)">已关注</div>
          </div>
        </div>
        <div class="flex items-center mt-4">
          <div class="w-14 h-6 text-black text-sm tag rounded-sm leading-6">
            AI生成
          </div>
          <div class="ml-4 text-[#3D3D3D] text-xl font-bold">
            {{ info.title }}
          </div>
        </div>
        <div class="w-full mt-4 text-left">{{ info.content }}</div>
        <el-divider></el-divider>
        <div>
          <div class="w-full text-left">创作信息</div>
          <div class="w-full min-h-16 bg-[#F4F5F7] rounded-xl mt-2 p-6">
            <div v-if="info.articleInfo?.openType == 1">
              <div class="flex justify-between">
                <div class="text-[15px] text-[#878787] font-medium">
                  生图方式
                </div>
                <div class="text-[#444444] text-[15px] font-medium">
                  {{
                    ['', '文生图', '', '图生图'][info.articleInfo.creatorType]
                  }}
                </div>
              </div>
              <div class="mt-4">
                <div
                  class="w-full text-left text-[15px] text-[#878787] font-medium"
                >
                  补充描述
                </div>
                <div class="mt-2 w-11/12 rounded-xl bg-white p-2 text-left">
                  {{ info.articleInfo.promptReverse || '无' }}
                </div>
              </div>
              <div class="mt-4 flex justify-between">
                <div class="text-[15px] text-[#878787] font-medium">参考图</div>
                <div
                  class="text-[#54C752] text-[15px] font-medium cursor-pointer"
                >
                  查看
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center" v-else>
              <img src="@/assets/hidden.svg" />
              <span class="mt-4 text-[#3D3D3D] font-medium"
                >作者设置了（{{
                  [
                    '',
                    '',
                    '仅关注可见',
                    '仅一键同款可见',
                    '一键同款+30积分可见'
                  ][info.articleInfo?.openType]
                }}）</span
              >
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div class="w-full text-left">基础信息</div>
          <div class="w-full min-h-16 bg-[#F4F5F7] rounded-xl mt-2 p-6">
            <div class="flex justify-between">
              <div class="text-[15px] text-[#878787] font-medium">创作者</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.creatorUser?.userName }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">创建时间</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.basicInfo?.createTime }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">画面尺寸</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.basicInfo?.size }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">模型主题</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.basicInfo?.modelTheme }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">生成模式</div>
              <div class="text-[#444444] text-[15px] font-medium">
                {{ info.basicInfo?.creatorType }}
              </div>
            </div>
            <div class="flex justify-between mt-2">
              <div class="text-[15px] text-[#878787] font-medium">所属合集</div>
              <div
                class="text-[#54C752] text-[15px] font-medium cursor-pointer"
              >
                查看
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-[1350px]">
      <comments :id="$route.params.id" :info="likeInfo"></comments>
    </div>
  </div>
</template>

<script>
import { getWorksDetail, followAuthor, cancelFollowAuthor } from '@/api/work'
import comments from '@/components/comments'
export default {
  components: {
    comments
  },
  data() {
    return {
      info: {},
      activeImage: 0
    }
  },
  computed: {
    likeInfo() {
      return {
        likeCount: this.info?.likeCount || 0,
        isLike: this.info?.isLike
      }
    }
  },
  methods: {
    async getInfo() {
      const id = this.$route.params.id
      if (!id) {
        this.$message.error('参数错误')
        this.$router.go(-1)
      }
      const result = await getWorksDetail(id)
      if (result) {
        this.info = result.data
      }
    },
    async followUser(follow = true) {
      const result = follow
        ? await followAuthor(this.info.creatorUser.userId)
        : await cancelFollowAuthor(this.info.creatorUser.userId)
      this.info.isAtten = !this.info.isAtten
      this.$message.success(result.msg)
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>
.tag {
  background: linear-gradient(
    104deg,
    #e8b45a 2%,
    rgba(235, 217, 100, 0.75) 97%,
    rgba(232, 203, 17, 0.51) 97%
  );
}
</style>