<template>
  <div v-if="value">
    <div
      class="z-[999] fixed top-0 left-0 w-full h-screen overflow-hidden bg-[rgba(0,0,0,.7)] flex justify-center items-center"
    >
      <div
        class="relative rounded-xl py-4"
        :style="{ height, width, background }"
      >
        <div class="close" @click="value = false">
          <img src="@/assets/close.svg" alt="" />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '780px'
    },
    height: {
      type: String,
      default: '680px'
    },
    background: {
      type: String,
      default: '#fff'
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>
  
  <style lang="scss">
.close {
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  right: -60px;
  top: -60px;
  cursor: pointer;
}
</style>