import workDetail from "@/views/works/detail"

export default [{
    path: "/works/detail/:id",
    name: "workDetail",
    component: workDetail,
    meta: {
      title: '作品详情'
    }
  },
]