import service from "@/utils/require"

/** 获取全部模型 */
export const getModelTheme = (params) => {
    return service.get("/modelTheme/getPage", {
        params: {
            page: 1,
            limit: -1
      }
    })
}

/** 创建任务 */
export const createJob = (params) => {
  return service.post("/goapiJob/createJob", params)
}

/** 获取用户生成所需要的电力券及权益卡信息 */
export const getUserGeneratorCoupon = (params) => {
    return service.get('/goapiJob/getUserGeneratorCoupon', { params })
}

/** 获取任务生成详情 */
export const getJobDetail = (id) => { 
  return service.get(`/goapiJob/getDetails/${id}`)
}

/** 撤销 */
export const revocationCreator = (id) => {
    return service.delete(`/goapiJob/revocationCreator/${id}`)
}

/** 重新生成任务 */
export const generatorAgain = (id) => {
  return service.post(`/goapiJob/createJobAgain/${id}`)
}

/** 保存任务 */
export const saveJob = (id) => {
  return service.post(`/userImageStorage/saveImageStorage/${id}`)
}

/** 图片升档（微调） */
export const upscalImg = (id, upscaleIndex) => {
  return service.post(`/goapiJob/upscaleImg/${id}/${upscaleIndex}`)
}

/** 获取用户历史记录 */
export const getUserHistory = (param) => {
  return service.get('/goapiJobItem/getUserGeneratorHistory', {
    params:{
      ...param,
      taskStatus: "finfish_success"   
    }
  })
}

/** 针对任务中的单个图片生成单个图片的详情任务 */
export const getSingleImg = (id, index) => { 
  return service.post(`/goapiJob/getSingleImg/${id}/${index}`)
}

/** 局部调整 */
export const inpaintImg = (params) => {
    return service.post(`/goapiJob/inpaintImg/${params.id}`, {
        ...params,
        id: undefined
    })
}

/** 获取条件 */

export const categoryList = (params) => {
    return service.get(`/mjInstructCategory/categoryList`, {
        params: {
          ...params
      }
    })
}

/** 随机生成词库 */

export const generateAutonomouslyMjContent = (params) => {
    return service.post(`/llm/generateAutonomouslyMjContent`, params)
}

/** 获取详情 */
export const getgeneratorDetail = (id) => {
  return service.get(`/llm/details/${id}`)
}