<template>
  <div>
    <sortHeader :searchKeys="searchKeys" defaultSearchKey="book"></sortHeader>
    <div class="grid grid-cols-5">
      <div class="w-80 h-96 border-b flex flex-col items-center justify-between">
        <div class="cursor-pointer" @click="$router.push('/discover/detail')">
          <div class="w-80 h-56 bg-[#F4F5F7] rounded-lg grid grid-cols-3 gap-2 p-1 ">
            <img src="@/assets/bg.png" alt="" class="w-24 h-24 rounded-lg">
            <img src="@/assets/bg.png" alt="" class="w-24 h-24 rounded-lg">
            <img src="@/assets/bg.png" alt="" class="w-24 h-24 rounded-lg">
            <img src="@/assets/bg.png" alt="" class="w-24 h-24 rounded-lg">
            <img src="@/assets/bg.png" alt="" class="w-24 h-24 rounded-lg">
            <img src="@/assets/bg.png" alt="" class="w-24 h-24 rounded-lg">
          </div>
          <div class="flex justify-between items-center mt-2">
            <div class="overflow-hidden whitespace-nowrap text-ellipsis w-44 font-bold text-lg">测试数据123456超出用三个点hdjkaxncknaskjbdhsdkhskjhdkjhaskhdkhnc</div>
            <div class="font-light text-base">24-07-31</div>
          </div>
        </div>
        <div class="flex justify-between items-center p-1 w-80">
          <div class="flex items-center">
            <img src="@/assets/bg.png" alt="" class="w-5 h-5 rounded-full">
            <span class="ml-2 font-normal">用户昵称</span>
          </div> 
          <div class="flex">
            <div class="flex items-center cursor-pointer">
              <img src="@/assets/star.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">3</span>
            </div>
            <div class="flex items-center cursor-pointer ml-4">
              <img src="@/assets/love.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">3</span>
            </div>
            <div class="flex items-center cursor-pointer ml-4">
              <img src="@/assets/discuss.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">3</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-80 h-96 border-b"></div>
      <div class="w-80 h-96 border-b"></div>
      <div class="w-80 h-96 border-b"></div>
      <div class="w-80 h-96 border-b"></div>
    </div>
  </div>
</template>

<script>
import sortHeader from '@/components/sortHeader'
export default {
  components: { sortHeader },
  name: "discover",
  data() {
    return {
      searchKeys: [{
        name: "合集",
        key: "book"
      },{
        name: "笔记",
        key: "page"
      }]
    }
  }
}
</script>

<style>

</style>